import {Box, Typography} from "@mui/material";
import {DateTime} from "luxon";
import React from "react";
import {MeetingDetailsItem} from "../../../../entity";

const LastMeetingCard = ({item, onCandidateClick, name}: {item: MeetingDetailsItem, name?: string, onCandidateClick: () => void}) => {

  return <Box sx={{display: 'flex', alignItems: 'center', gap: 3, background: 'white', border: '1px solid #E4E4E4', borderRadius: '10px', p: '16px 18px', mb: '20px'}}>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#E4E4E7', borderRadius: '24px', width: 48, height: 48}}>
      <Box component={'img'} src={`/img/teenyicons/appointments.svg`} width={22} height={22} />
    </Box>
    <Box flex={1}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', mb: '6px'}}>
        <Typography  sx={{fontWeight: 600}}>{item.positionTitle}</Typography>
        <Box sx={{background: '#2C92D5', borderRadius: '24px', width: 4, height: 4}} />
        <Typography  sx={{fontWeight: 600}}>{DateTime.fromISO(item.date).toFormat('LLL d, h:mm a')} - {DateTime.fromISO(item.date).plus({hour: 1}).toFormat('h:mm a')}</Typography>
      </Box>
      <Typography  onClick={onCandidateClick} sx={{fontWeight: 600, color: '#3075B8', cursor: 'pointer', textDecoration: 'underline', width: 'fit-content'}}>{name || `Candidate #${item.candidateNumber}`}</Typography>
    </Box>
  </Box>
}

export default LastMeetingCard;
