import {Box, Button, styled} from "@mui/material";
import React, {useContext} from "react";
import {useCancelMeeting} from "../api/useCancelMeeting";
import {RescheduleMeetingClickHandlerContext} from "../lib/RescheduleMeetingClickHandlerContext";
import useIsContractor from "../../../shared/hooks/useIsContractor";

interface Props {
  meeting: {
    zoomMeetingLink?: string,
    id?: string,
  },
  onCancelMeeting: (meetingId: string) => void,
  onButtonClick?: () => void,
  positionMatchId?: string,
}

export function MeetingControlButtons({meeting, onCancelMeeting, positionMatchId, onButtonClick}: Props) {
  const cancelMeeting = useCancelMeeting(() => onCancelMeeting(meeting.id!));
  const {handler} = useContext(RescheduleMeetingClickHandlerContext);
  const isContractor = useIsContractor();

  const handleJoinClick = () => {
    onButtonClick && onButtonClick();
    window.open(`/interview-meeting-v2/${meeting.id}`)
  }

  const handleRescheduleClick = () => {
    onButtonClick && onButtonClick();
    if (positionMatchId && meeting.id) {
      handler(positionMatchId, meeting.id);
    } else throw new Error('Meeting has no Id or positionMatchId')
  }

  const handleCancelClick = async (meetingId?: string) => {
    onButtonClick && onButtonClick();
    if (!meetingId) throw new Error('Meeting id is not set');
    await cancelMeeting(meetingId);
  }


  return <Box sx={{mt: 2, display: 'flex', gap: 1, justifyContent: 'space-between'}}>
    <StyledButton variant={'text'}
                  onClick={handleJoinClick}>
      Join
    </StyledButton>

    {!isContractor &&
      <StyledButton variant={'text'}
                    onClick={handleRescheduleClick}>
        Reschedule
      </StyledButton>
    }

    {!isContractor &&
      <StyledButton variant={'text'} onClick={() => handleCancelClick(meeting.id)}>
        Cancel meeting
      </StyledButton>
    }
  </Box>
}

const StyledButton = styled(Button)({
  paddingLeft: 0,
  paddingRight: 0,
  fontWeight: 500,
  minWidth: 0
})