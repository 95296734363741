import * as React from "react";
import {Box, Button, FormControl, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";

interface Props {
  profilePersonalSummary?: string | null,
  cvPersonalSummary?: string | null,
  onSubmit: (value: {personalSummary: string}) => void,
  loading: boolean,
  onBackClick: () => void,
}

export default function PersonalSummaryCompare(props: Props) {
  const {profilePersonalSummary, cvPersonalSummary, onBackClick, loading, onSubmit} = props;

  return <Box component={'form'} flex={4} onSubmit={() => onSubmit({personalSummary: cvPersonalSummary || ''})}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
      <FormControl>
        <Box sx={{display: 'flex' , gap: 1}}>
          <Box sx={{display: 'flex', flexDirection: 'column', width:'50%'}}>
            <Typography sx={{fontWeight: 700, fontSize: 24}}>MDliason profile</Typography>
            <Box sx={{display: 'flex', flex: 1, flexDirection: 'column', background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px', lineHeight: 1.5}} dangerouslySetInnerHTML={{__html: profilePersonalSummary || ''}} />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width:'50%'}}>
            <Typography sx={{fontWeight: 700, fontSize: 24}}>Your resume</Typography>
            <Box sx={{display: 'flex', flex: 1, flexDirection: 'column', background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px', lineHeight: 1.5}} dangerouslySetInnerHTML={{__html: cvPersonalSummary || ''}} />
          </Box>
        </Box>
        <Box sx={{mt: 3, display: 'flex', gap: 3}}>
          <Button variant={'outlined'} color={'secondary'} onClick={onBackClick}>Back</Button>
          <LoadingButton sx={{marginLeft: 'auto'}} variant={'contained'} loading={loading} type="submit">Copy</LoadingButton>
        </Box>
      </FormControl>
    </Box>
  </Box>
}
