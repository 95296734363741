import {
  Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle,
  Divider, FormControl, FormHelperText,
  FormLabel, IconButton,
  Input,
  Stack,
  Tab,
  tabClasses,
  Tabs,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Copy, Repeat} from "phosphor-react";
import {useFormik} from "formik";
import * as yup from "yup";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../config";

interface ImproveRoleDescriptionModalProps {
  onClose: (result?: string) => void,
  open: boolean,
  message?: string,
  companyName?: string,
}

export default function ImproveRoleDescriptionModal(props: ImproveRoleDescriptionModalProps) {
  const [result, setResult] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ImprovementDialogFormData>()
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setData(prev => ({
      years: prev?.years || '',
      companyName: props.companyName || '',
      message: props.message || ''
    }))
  }, [props.companyName, props.message]);

  useEffect(() => {
    if (props.open) {
      setTabIndex(0);
      setLoading(false);
      setResult(undefined);
    }
  }, [props.open]);

  const generate = (values?: ImprovementDialogFormData) => {
    if (!values) {
      values = data;
    }
    setResult(undefined)
    setTabIndex(0)
    setLoading(true)
    fetch(
      config.apiUrl + '/api/generate',
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(res => res.json()).then(res => {
      setResult(res.result)
      setTabIndex(1);
    })
      .finally(() => setLoading(false))
  }

  const submit = (values: ImprovementDialogFormData) => {
    setData(values)
    generate(values)
  }

  return <Dialog open={props.open} onClose={() => props.onClose}>
    <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography component="h2">Improve Role Description</Typography>
      <IconButton
        onClick={() => { props.onClose() }}
        sx={{'&:hover': {backgroundColor: 'rgba(255,255,255, 0.1)', borderRadius: '50%'}}}
      >
        <img src={'/img/teenyicons/x.svg'} width={16} height={16} />
      </IconButton>
    </DialogTitle>
    <Divider sx={{my: 1}} />
    <DialogContent>
      <Tabs value={tabIndex}
            onChange={(e, val) => setTabIndex(val as number)}
             sx={{
               overflow: 'hidden',
               width: '100%',
               maxWidth: 400,
               alignSelf: 'flex-start',
               [`& .${tabClasses.root}`]: {
                 bgcolor: 'transparent',
                 boxShadow: 'none',
                 '&:hover': {
                   backgroundColor: 'transparent',
                 },
                 [`&.${tabClasses.selected}`]: {
                   color: 'primary.plainColor',
                   fontWeight: 'lg',
                   '&:before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     zIndex: 1,
                     bottom: '-1px',
                     left: 'var(--ListItem-paddingLeft)',
                     right: 'var(--ListItem-paddingRight)',
                     height: '3px',
                     borderTopLeftRadius: '3px',
                     borderTopRightRadius: '3px',
                     backgroundColor: 'primary.500',
                   },
                 },
               },
             }}
        >
          <Tab value={0} title={'Initial data'} />
          <Tab value={1} disabled={!result} title={'Result'} />
      </Tabs>
      <Box p={2} sx={{position: 'relative', overflow: 'auto'}}>
        {loading && <Box sx={{
          zIndex: 3,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,0.8)'
        }}>
          <CircularProgress size="15"/>
        </Box>}
        {tabIndex === 0 && <ImprovementDialogForm initial={data} onSubmit={submit} />}
        {tabIndex === 1 && <div>
            <div dangerouslySetInnerHTML={{__html: result || ''}} />
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                <Button startIcon={<Repeat size={24} color="#6FB6FF" weight="regular" />}
                        variant={'outlined'}
                        onClick={() => generate()}
                >
                    Regenerate
                </Button>
                <Button startIcon={<Copy size={24} color="#fff" weight="regular" />}
                        onClick={() => props.onClose(result)}
                >
                    Copy to description
                </Button>
            </Box>
        </div>}
      </Box>
    </DialogContent>
  </Dialog>
}

const Editor = CKEditor as any;

const schemaFields: any = {
  years: yup
    .string()
    .required('This is required'),
  message: yup
    .string()
    .required('This is required'),
  companyName: yup
    .string()
    .required('This is required'),
}

interface ImprovementDialogFormData {
  years: string,
  message: string,
  companyName: string
}

interface ImprovementDialogFormProps {
  initial?: ImprovementDialogFormData,
  onSubmit: (data: ImprovementDialogFormData) => void
}

const ImprovementDialogForm = (props: ImprovementDialogFormProps) => {
  const formik = useFormik({
    initialValues: {
      years: '',
      message: '',
      companyName: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        props.onSubmit(values)
      }
    },
    validateOnChange: true
  });

  React.useEffect(() => {
    if (props.initial) {
      formik.setValues({
        years: props.initial.years || '',
        message: props.initial.message || '',
        companyName: props.initial.companyName || '',
      })
    }
  }, [props.initial]);

  return <form onSubmit={formik.handleSubmit}>
    <Stack spacing={3}>
      <FormControl>
        <FormLabel>Company name</FormLabel>
        <Input placeholder="Company name"
               name="companyName"
               data-test="companyName"
               value={formik.values.companyName}
               onChange={formik.handleChange}
               error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        />
        {(formik.touched.companyName && formik.errors.companyName) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.companyName}</FormHelperText>}
      </FormControl>
      <FormControl>
        <FormLabel>Years of experience</FormLabel>
        <Input placeholder="Years"
               name="years"
               data-test="years"
               autoFocus
               value={formik.values.years}
               onChange={formik.handleChange}
               error={formik.touched.years && Boolean(formik.errors.years)}
        />
        {(formik.touched.years && formik.errors.years) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.years}</FormHelperText>}
      </FormControl>
      <FormControl>
        <FormLabel>Position description</FormLabel>
        <Editor
          editor={ ClassicEditor }
          data={formik.values.message}
          data-test="message"
          config={ {
            toolbar: ["undo", "redo", "|", "heading", "|", "bold", "italic", "|", "link", "|", "bulletedList", "numberedList", "outdent", "indent"]
          }}
          onReady={ (editor: any) => {
            (window as any)['CKEDITOR'] = editor;
            // You can store the "editor" and use when it is needed.
            console.log( 'Editor is ready to use!', (window as any)['CKEDITOR'] );
          } }
          onChange={ ( event: any, editor: any ) => {
            const data = editor.getData();
            formik.setFieldValue('message', data)
          } }
        />
        {(formik.touched.message && formik.errors.message) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.message}</FormHelperText>}
      </FormControl>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button type={'submit'}>Submit</Button>
      </Box>
    </Stack>
  </form>
}
