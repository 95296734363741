import {MeetingCard} from "./MeetingCard";
import {Box, Button, Divider, styled, Typography} from "@mui/material";
import React, {useState} from "react";
import {GuestPerson} from "../../../api/sdl";
import {isDateInFuture, meetingSortFunc} from "../lib/utils";
import * as _ from "lodash";

import {AppointmentsIcon, RoundedIcon} from "../../../shared";
import {MeetingControlButtons} from "./MeetingControlButtons";
import {useHistory} from "react-router-dom";

interface MeetingListItem {
  meetingScheduledOn: string,
  id?: string,
  zoomMeetingLink: string,
  guests: GuestPerson[],
}

interface Props {
  meetings: MeetingListItem[],
  onCancelMeeting: (meetingId: string) => void,
  onScheduleClick: () => void,
  positionMatchId: string,
  title: string,
  showNextMeetingButton?: boolean,
}

export function MeetingList({meetings, title, positionMatchId, onCancelMeeting, onScheduleClick, showNextMeetingButton = true}: Props) {
  const [showPastMeetings, setShowPastMeetings] = useState(false);

  const sortedMeetings = _.uniqBy(meetings, 'id').sort(meetingSortFunc);
  const futureMeetings = sortedMeetings.filter(meeting => isDateInFuture(meeting.meetingScheduledOn));
  const pastMeetings = sortedMeetings.filter(meeting => !isDateInFuture(meeting.meetingScheduledOn));


  return <Box sx={{gap: 2, display: 'flex', flexDirection: 'column', maxWidth: '300px'}}>
    <Box data-test='meeting-cards' sx={{gap: 1, display: 'flex', flexDirection: 'column'}}>
      {!!meetings.length && <Typography variant={'h6'} sx={{fontSize: '16px', lineHeight: '19px',}}>{title}</Typography>}
      {!!futureMeetings.length && <FutureMeetingContainer>
        {futureMeetings.map((meeting, i) => <React.Fragment key={meeting.id}>
          <MeetingCard slots={{
                         icon: <RoundedIcon background={'#37C9EF'}
                                            iconSize={20} size={36}
                                            icon={<AppointmentsIcon stroke={'#3075B8'} />} />,
                         button: <MeetingControlButtons meeting={meeting}
                                                        positionMatchId={positionMatchId}
                                                        onCancelMeeting={onCancelMeeting} />
                       }} 
                       meeting={meeting}
          />
          {i < futureMeetings.length - 1 && <Divider sx={{borderColor: '#D4D8DB', mx: -3}}/>}
        </React.Fragment>)}
      </FutureMeetingContainer>}
    </Box>

    {(showNextMeetingButton || !!pastMeetings.length) &&
      <Box sx={{display: 'flex', flexDirection: 'column' , width: 1, justifyContent: 'space-between', gap: 2}}>
        {showNextMeetingButton &&
          <Button color={'primary'} sx={{px: 1.5}}
                  onClick={onScheduleClick} variant={"contained"}>
            Schedule next meeting
          </Button>
        }
        {!!pastMeetings.length && (
          <Button variant={'text'} sx={{fontWeight: 500, px: 1.5}} onClick={() => setShowPastMeetings(prev => !prev)}>
            {showPastMeetings ? 'Hide' : 'Show'} past meetings
          </Button>
        )}
      </Box>}

    {(!!pastMeetings.length && showPastMeetings) && <PastMeetingContainer>
      {pastMeetings.map((meeting, i) => <React.Fragment key={meeting.id}>
        <MeetingCard slots={{
                       icon: <RoundedIcon iconSize={20} size={36}
                                          icon={<AppointmentsIcon />} />
                     }}
                     meeting={meeting}/>
        {i < pastMeetings.length - 1 && <Divider sx={{borderColor: '#D4D8DB', mx: -3}}/>}
      </React.Fragment>)}
    </PastMeetingContainer>}

  </Box>
}

const FutureMeetingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '16px 24px',
  borderRadius: '10px',
  border: '1px solid #D8E9F3',
  background: '#E1F5FB'
})

const PastMeetingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '16px 24px',
  borderRadius: '10px',
  border: '1px solid #D8E9F3',
  background: '#FFF'
})
