import {Box, Card, Typography, Button, IconButton} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import {PlusCircle, X, Warning} from 'phosphor-react';
import {
  EmailTemplate,
  useAllEmailTemplatesQuery, useDeleteEmailTemplateMutation,
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../components/DataGrid";
import {useConfirm} from "../../../shared/ConfirmDialogContext";


const columns: (onDelete: (id: string) => void) => DataGrigColumn<EmailTemplate>[]  = (onDelete: (id: string) => void) => [{
  title: 'Name',
  value: o => o.name
}, {
  title: 'Subject',
  value: o => o.subject,
}, {
  title: '',
  value: o => <IconButton size={'small'} color={'secondary'} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(o.id)
  }}>
    <X size={24} color="#A10E25" weight="regular" />
  </IconButton>,
}]

export default function EmailList() {
  const history = useHistory();
  const {data, loading, refetch, error} = useAllEmailTemplatesQuery({fetchPolicy: 'no-cache'});
  const [deleteEmail, {loading: deleteLoading}] = useDeleteEmailTemplateMutation();
  const confirm = useConfirm();

  const onDeleteClick = (id: string) => confirm({
    content: 'Are you sure you want to delete this template?'
  }).then(val => val && deleteEmail({variables: {id}}).then(() => refetch()));

  const rows = React.useMemo(() => {
    return data?.allEmailTemplates || [];
  }, [data?.allEmailTemplates])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 4}}>
      <Typography variant={'h3'} sx={{ml: 2}}>Email templates</Typography>
      <Button variant={'contained'} color={'primary'}
              onClick={() => history.push('/email')}
              startIcon={<PlusCircle size={24} color="#ffffff" weight="regular" />}>Add</Button>
    </Box>
    <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns(onDeleteClick)}
                rows={rows}
                hidePaginator
                rowsPerPage={1000}
                page={0}
                error={error?.message}
                onRowClick={row => history.push('/email/' + row.id)}
                onNextClick={() => {}}
                onPrevClick={() => {}}
                loading={loading || deleteLoading}
                isLastPage
                />
    </Card>
  </div>
}
