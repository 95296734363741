import {Box, Button, Container, Divider} from "@mui/material";
import {SignIn, SignOut} from "phosphor-react";
import React, {PropsWithChildren, useContext} from "react";
import {useHistory} from "react-router";
import Stepper from "../../components/Stepper";
import useIsMobile from "../../../shared/hooks/useIsMobile";
import OnboardingBottomNav from "../../components/OnboardingBottomNav";
import {KeycloakContext} from "../../../keycloak";

export default function OnboardingLayout(props: PropsWithChildren<{}>) {
  const {keycloak} = useContext(KeycloakContext);
  const history = useHistory();
  const isMobile = useIsMobile();

  return <Container maxWidth={'lg'} sx={{mb: 10}}>
    <Box sx={{display: 'flex', width: 1, height: 80, mb: 3, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box component={'img'} src={'/img/MDliaison_logo.png'} width={200} height={44} />
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        {!keycloak?.authenticated && <>
          <Button variant={'outlined'} color={'primary'}
                  onClick={() => history.push('/company')}
          >Sigh up as company</Button>
          <Button variant={'outlined'} color={'primary'}
                  data-test="login"
                  onClick={() => document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})}
                  startIcon={<SignIn size={24} color="#37c9ef" weight="regular" />}>Login</Button>
        </>}
        {!!keycloak?.authenticated && <Button variant={'outlined'} color={'primary'}
                                              onClick={() => {
                                                keycloak.logout()
                                              }}
                                              startIcon={<SignOut size={24} color="#37c9ef" weight="regular" />}>Logout</Button>}
      </Box>
    </Box>
    <Box>
      <Box sx={{display: 'flex', gap: 4}}>
        {!isMobile && <>
            <Box flex={1}>
                <Stepper />
            </Box>
            <Divider orientation={'vertical'} />
        </>}
        <Box sx={{flex: 4, width: 1}}>
          {props.children}
        </Box>
        {isMobile && <OnboardingBottomNav />}
      </Box>
    </Box>
  </Container>
}

export const withOnboardingLayout = (WrappedComponent: any) => (props: any) => {
  return <OnboardingLayout>
    <WrappedComponent {...props}/>
  </OnboardingLayout>
}
