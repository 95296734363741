import React from 'react';
import {JointProfileOutput, LastScheduledEmailsQuery, useLastScheduledEmailsQuery} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../../admin/components/DataGrid";
import {DateTime} from "luxon";


type lastScheduledEmailsData = LastScheduledEmailsQuery["lastScheduledEmails"][0]
const columns: DataGrigColumn<Partial<lastScheduledEmailsData>>[]  = [{
  title: 'Scheduled On',
  value: e => DateTime.fromISO(e.scheduledAt).toFormat('LL/dd/yyyy hh:mm a')
},
  {
    title: "Recipient",
    value: e => e.recipient || "N/A"
  },
  {
    title: "template",
    value: e => e.templateName || "N/A",
  }
  /*{
  title: 'Name',
  value: o => o.profile?.firstName + ' ' + o.profile?.lastName
}, {
  title: 'Email',
  value: o => o.profile?.email || '',
}, {
  title: 'Status',
  value: o => o.contractorProfile?.status || '',
}, {
  title: 'Locations',
  value: o =>
    o.contractorProfile?.cities.map((it) => `${it.city}, ${it.stateShort}`).join("; ") || ''
  ,
}, {
  title: 'CV',
  value: o => o.contractorProfile?.hasParsedCv ? 'Y' :'',
},*/]
export function LastScheduledEmails() {


  const {data, loading, } = useLastScheduledEmailsQuery()

  console.log(data);
  return <>

    <DataGrid columns={columns}
              rowsPerPage={100}
              total={100}
              page={0}
              onPrevClick={() => {}}
              onNextClick={() => {}}
              rows={data?.lastScheduledEmails || []}
              loading={loading}
    />
  </>
}