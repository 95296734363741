import React, {useContext} from "react";
import useIsMobile from "../../shared/hooks/useIsMobile";
import Loading from "../../shared/components/Loading";
import {Alert, Box, Container} from "@mui/material";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import PositionView, {
  PositionViewCurrentExperience,
  PositionViewJobDetails
} from "../position-view/PositionView";
import {PositionApplicationFeatureTemp} from "../../feature/position-apply/PositionApplicationFeatureTemp";
import {ContractorAlreadyApplied} from "../../feature/position-apply";
import PositionApproveBottomNav from "../../contractor/components/PositionApproveBottomNav";
import {PositionMatchContext} from "../../entity/position-match/PositionMatchContext";
import EditableUserCalendar from "../../shared/components/Calendar/EditableUserCalendar";
import {ProfileContext} from "../../shared/ProfileContext";
import {SlotAvailabilityAlert} from "./ui/SlotAvailabilityAlert";

const ContractorPositionWidget = () => {

  const isMobile = useIsMobile();
  const {loading, position, meetingSlotsAreAvailable, positionMatch, scheduleUpdated} = useContext(PositionMatchContext);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [profile] = useContext(ProfileContext)

  return <Loading in={loading}>
    <Container sx={{p: 3, my: {xs: 4, md: 3}}} maxWidth={'lg'}>
      <Box mb={2}>
        <Breadcrumbs crumbs={[{title: 'Roles', link: '/positions'}, {title: position?.title || 'Loading...'} ]} />
      </Box>

      {!isMobile && <>

          <Box mb={2}>
            <PositionView position={position!} />
          </Box>

        {(!position?.contractorAlreadyApplied || !positionMatch?.expectedRate  ) &&
            <PositionApplicationFeatureTemp position={position!} meetingSlotsAreAvailable={meetingSlotsAreAvailable} positionMatchId={positionMatch?.id} />}

        {meetingSlotsAreAvailable === false && <SlotAvailabilityAlert />}
        {(position?.contractorAlreadyApplied && !!positionMatch?.expectedRate) && <ContractorAlreadyApplied />}

          <EditableUserCalendar
              onCalendarUpdate={scheduleUpdated}
              contractorId={profile!!.contractorProfile!!.contractorId}
          />
      </>}

      {isMobile && <>
        {meetingSlotsAreAvailable === false && <SlotAvailabilityAlert/>}

        {(!position?.contractorAlreadyApplied || !positionMatch?.expectedRate  ) &&
            <PositionApplicationFeatureTemp position={position!} meetingSlotsAreAvailable={meetingSlotsAreAvailable} positionMatchId={positionMatch?.id} />}

        {(position?.contractorAlreadyApplied && !!positionMatch?.expectedRate) && <ContractorAlreadyApplied />}

        {tabIndex === 0 && <Box mt={2}>
            <PositionViewJobDetails position={position} />
            <PositionViewCurrentExperience position={position} />
        </Box>}
        {tabIndex === 1 && <>
            <Box sx={{width: 1, '& p': {lineHeight: 1.5}}} dangerouslySetInnerHTML={{__html: position?.description || ''}} />
        </>}
        {tabIndex === 2 && <>
            <EditableUserCalendar
                onCalendarUpdate={scheduleUpdated}
                contractorId={profile!!.contractorProfile!!.contractorId}
            />
        </>}
          <PositionApproveBottomNav index={tabIndex} onChange={setTabIndex} />
      </>}

    </Container>
  </Loading>
}

export default ContractorPositionWidget;
