import {useIsAdmin} from "../../../shared";
import React from "react";
import {Box} from "@mui/material";
import {ParseResumeButton, StripeAccount, UploadResumeButton} from "../../../feature";
import DownloadResumeButton from "./DownloadResumeButton";
import {ContractorCardInnerProps} from "../lib/types";
import CurrentExperience from "./current-expirience";
import ContactInformation from "./contact-information";
import useSaveCurrentExperience from "../api/use-save-current-experience";
import useSaveProfile from "../api/use-save-profile";
import config from "../../../config";
import {DownloadGeneratedResume} from "./download-generated-resume";

export const Side = (props: ContractorCardInnerProps) => {
  const {profile, editable, onEdit, getCvHints, extraContent, userProfile} = props;
  const isAdmin = useIsAdmin();
  const [resumeUrl, setResumeUrl] = React.useState<string>();

  const currentExperienceApi = useSaveCurrentExperience(profile?.contractorId);
  const saveProfileApi = useSaveProfile(profile?.contractorId);

  const showParseResumeButton = !!resumeUrl && profile?.cvType === "application/pdf" && isAdmin && editable;
  const showDownloadResumeButton = resumeUrl && (isAdmin || editable);

  React.useEffect(() => {
    setResumeUrl(profile?.cvUrl || undefined)
  }, [profile?.cvUrl]);

  return <Box sx={{maxWidth: {md: 320}, mt: {xs: 2, md: 0}, display: 'flex', flexDirection: 'column', gap: 2, width: 1}}>

    {extraContent || null}

    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2, flexDirection: "column"}}>
      {(editable) && (
        <UploadResumeButton contractorId={isAdmin ? profile?.contractorId : undefined}
                            onUpload={setResumeUrl} />
      )}

      {showDownloadResumeButton && <DownloadResumeButton resumeUrl={resumeUrl}
                                                         fileStorage={profile?.fileStorage || undefined} />}
    </Box>
    {showParseResumeButton && (
      <ParseResumeButton contractorId={profile?.contractorId}
                         onCompleted={getCvHints}/>
    )}

    {(isAdmin || editable) &&
      <ContactInformation contractorProfile={profile}
                          profile={userProfile}
                          editable={editable}
                          onFormSubmit={async values => {
                            const {linkedInUrl, cities} = values;

                            await saveProfileApi.save({
                              linkedInUrl,
                              cities,
                            });

                            onEdit && onEdit();
                          }}
                          loading={saveProfileApi.loading} />}
      <CurrentExperience profile={profile}
                       editable={editable}
                       onFormSubmit={async values => {
                         await currentExperienceApi.save(values);
                         props.onUpdate && props.onUpdate();
                       }}
                       loading={currentExperienceApi.loading} />
    {props.showSideStripe && <StripeAccount contractorId={profile?.contractorId}/>}
    {isAdmin &&
        <DownloadGeneratedResume url={profile?.generatedResumeUrl} />}

  </Box>
}
