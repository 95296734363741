import {ApolloClient, InMemoryCache, HttpLink, from, ApolloLink} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import config from "./config";
import {keycloak} from "./keycloak";


export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

const errorLink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch(() => {
        reject();
      })
  });
}

const customFetch = async (uri: string, options: any) => {
  const token = await getToken().catch(e => {
    // throw new Error('Update token failed');
  });
  options.headers.Authorization = token ? `Bearer ${token}` : "";
  return fetch(uri, options);
};

const httpLink = new HttpLink({
  uri: `${config.apiUrl}/graphql`,
  headers: {
    "content-type": "application/json",
  },
  fetch: customFetch
});

const repsHttpLink = new HttpLink({
  uri: `${config.apiUrl}/reps/graphql`,
  headers: {
    "content-type": "application/json",
  },
  fetch: customFetch
});


const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export const getApolloClient = (apiUrl: string, token: string) => {
  const link = new HttpLink({
    uri: `${apiUrl}/graphql`,
    headers: {
      "content-type": "application/json",
    },
    fetch: async (uri: string, options: any) => {
      options.headers.Authorization = token ? `Bearer ${token}` : "";
      return fetch(uri, options);
    }
  });

  return new ApolloClient({
    link: from([cleanTypeName, link]),
    cache: new InMemoryCache(),
  });
}

/*
export const getToken = () => {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch(() => {
        reject();
      })
  });
}

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(e => {
    throw new Error('Update token failed');
  });
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    }
  };
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;
*/


export const client = new ApolloClient({
  link: from([cleanTypeName, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export const repsClient = new ApolloClient({
  link: from([cleanTypeName, errorLink, repsHttpLink]),
  cache: new InMemoryCache(),
});

/*
export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

*/