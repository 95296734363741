import React from "react";
import {useAffindaCvHintsLazyQuery} from "../../../api/sdl";

export default function useCvProfile(editable: boolean, contractorId?: string) {
  const [getCvHints, cvHintsData] = useAffindaCvHintsLazyQuery({
    fetchPolicy: "no-cache",
    variables: {contractorProfileId: contractorId}
  })

  React.useEffect(() => {
    if(editable == true) {
      getCvHints();
    }
  }, [editable])


  const cvProfile = React.useMemo(() => {
    return cvHintsData.data?.affindaCVHints
  }, [cvHintsData])

  return {cvProfile, getCvHints};
}
