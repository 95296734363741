import React, {useContext} from "react";
import {KeycloakContext, KeycloakRoles} from "../../../keycloak";
import config from "../../../config";
import {isMobile} from "react-device-detect";
import {useLocation} from "react-router-dom";

export function useMixpanel() {
  const location = useLocation();

  const {initialized, keycloak} = useContext(KeycloakContext);

  React.useEffect(() => {
    if (location.pathname && initialized && keycloak?.authenticated) {
      const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
      let role;

      if (roles.includes('ROLE_ADMIN')) {
        role = KeycloakRoles.admin;
      } else if (roles.includes('ROLE_COMPANY')) {
        role = KeycloakRoles.company;
      } else {
        role = KeycloakRoles.contractor;
      }

      const eventData = {
        user_type: role,
        page_canonical: document.location.origin + document.location.pathname,
        email: keycloak?.tokenParsed?.email,
        kc_user_id: keycloak?.tokenParsed?.sub,
        device_type: isMobile ? 'mobile' : 'desktop'
      }

      if (config.isProd) {
        try {
          (window as any).mixpanel.track('page_view', eventData)
        }
        catch (e) { console.log(e) }

      } else {
        console.log('page_view', eventData);
      }
    }
  }, [location.pathname, keycloak?.authenticated])

  return null;
}