import {Box, Button, Typography} from "@mui/material";
import MyInterview from "../../../shared/components/ContractorCard/sections/MyInterviewFrame";
import IntroductionVideoView from "../../../shared/components/IntroductionVideoView";
import React from "react";
import {useContractorAnswersByIdQuery, useSaveVideoSettingsMutation} from "../../../api/sdl";
import {Editable} from "../../../shared";
import useIsAdmin from "../../../shared/hooks/useIsAdmin";
import {ContractorProfile} from "../../../entity/contractor";

export default function Videos(props: {profile?: ContractorProfile, onVideoEditClick?: () => void} & Editable) {
  const {profile, editable, onEdit, onVideoEditClick} = props;
  const [useExternalVideos, setUseExternalVideos] = React.useState(false);
  const isAdmin = useIsAdmin();
  const {data: answersData, loading: answersLoading} = useContractorAnswersByIdQuery({fetchPolicy: 'no-cache', variables: {id: profile?.contractorId}});

  React.useEffect(() => {
    if (typeof profile?.useExternalVideos !== 'undefined') {
      setUseExternalVideos(!!profile?.useExternalVideos)
    }
  }, [profile?.useExternalVideos])

  return <>
    <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mb: 1, justifyContent: 'space-between'}}>
      <Typography sx={{fontWeight: 700, fontSize: 24}}>My sales interview video</Typography>
      {(editable && onVideoEditClick && !useExternalVideos) && <Button size={'small'} variant={'outlined'} onClick={() => onVideoEditClick()}>Edit</Button>}
    </Box>
    <Typography sx={{mt: 1, mb: 3}}>Learn more about my sales experience by viewing my sales interview.</Typography>

    {!!useExternalVideos && <MyInterview editable={editable && isAdmin} profile={profile} onSave={() => onEdit && onEdit()} />}
    {!useExternalVideos && <IntroductionVideoView loading={answersLoading} answers={answersData?.contractorAnswersById || []}/>}
  </>
}
