import {useHistory} from "react-router-dom";
import {Box, Button} from "@mui/material";
import React from "react";
import {MeetingDetails} from "./MeetingDetails";
import {MeetingDetailsItem} from "../lib/types";
import {scheduleMeetingLink} from "../lib/utils";

const PastMeetingRowItem = ({item, hired}: {item: MeetingDetailsItem, hired: boolean}) => {
  const history = useHistory();

  return <Box sx={{display: 'flex', alignItems: 'center', gap: 3, background: 'white', border: '1px solid #E4E4E4', borderRadius: '10px', p: '16px 18px', mb: '20px'}}>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, background: '#E4E4E7', borderRadius: '24px', width: 48, height: 48}}>
      <Box component={'img'} src={`/img/teenyicons/appointments.svg`} width={22} height={22} />
    </Box>

    <MeetingDetails item={item} />
    {!hired && <Button data-test='reschedule' onClick={() => history.push(scheduleMeetingLink({positionId: item.positionId, positionMatchId: item.positionMatchId}))}>Schedule next meeting</Button>}
    {!hired && <Button data-test='hire' onClick={() => history.push('/hire/' + item.positionId + '/' + item.positionMatchId)}>Hire contractor</Button>}
  </Box>
}

export {PastMeetingRowItem}