import {RouteComponentProps, useHistory} from "react-router-dom";
import {Box, Button, Container, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PositionMatchStatus,} from "../../../api/sdl";
import {ContractorCard} from "../../../widget";
import Approval from "../../components/Approval";
import Loading from "../../../shared/components/Loading";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import {expectedSalaryFromPositionMatch} from "../../../shared";
import {ProfileContext} from "../../../shared/ProfileContext";
import CandidateCarousel from "./ui/CandidateCarousel";
import useRejectCandidateClick from "./hooks/useRejectCandidateClick";
import useCandidatesByPosition from "./hooks/useCandidatesByPosition";
import usePositionById from "../../../shared/hooks/usePositionById";
import useCandidateHasContract from "./hooks/useCandidateHasContract";
import {MeetingList} from "../../../entity";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../../components/RescheduleMeetingClickHandlerContextProvider";

enum Mode {Preview, Approvement}

export function Candidates(props: RouteComponentProps<{ positionId: string, positionMatchId?: string }> & {schedule?: boolean}) {
  const {positionId, positionMatchId} = props.match.params;
  const [profile] = React.useContext(ProfileContext);
  const [mode, setMode] = useState(props.schedule ? Mode.Approvement : Mode.Preview)
  const history = useHistory();
  const {position} = usePositionById(positionId);
  const {candidates, refetch, loading} = useCandidatesByPosition(positionId, positionMatchId);
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0);

  const currentCandidate = candidates[currentCandidateIndex];

  const meetings = currentCandidate?.meetings || [];

  const hasContract = useCandidateHasContract(currentCandidate?.contractorId, profile?.company?.id);

  const onRejectClick = useRejectCandidateClick(refetch, currentCandidate?.id);

  const handleCandidateSelect = (newIndex: number) => {
    setCurrentCandidateIndex(newIndex);
    setMode(Mode.Preview);
  }

  const onCancelMeeting = (meetingId: string) => {
    refetch();
  }

  return <RescheduleMeetingClickHandlerContextProvider>
    <Box>
    <Loading in={loading}>
      <Box sx={{background: 'white', borderBottom: '1px solid #ECEFEF'}}>
        <Container sx={{py: 4}} maxWidth={'lg'}>
          <Breadcrumbs crumbs={[{title: 'Roles', link: '/positions'}, {
            title: position?.title || 'Loading...',
            link: '/position/' + positionId
          }, {title: 'Candidates'}]}/>
          <CandidateCarousel candidates={candidates}
                             onClick={handleCandidateSelect}
                             active={currentCandidate?.contractorId}/>
        </Container>
      </Box>
      <Container sx={{my: 4}} maxWidth={'lg'}>

        {(!candidates?.length) && <Box sx={{
          width: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 80px)'
        }}>
            <Typography>No candidates</Typography>
        </Box>}

        {currentCandidate && <>
          {mode === Mode.Preview && (
            <ContractorCard profile={currentCandidate.contractorProfile}
                            firstName={currentCandidate.displayName}
                            lastName={undefined}
                            showSideStripe={false}
                            extraContent={<>
                              {(currentCandidate?.status !== PositionMatchStatus.CompanyScheduledCall && currentCandidate?.status !== PositionMatchStatus.CompanyDeclined) &&
                                  <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                                      <Button data-test='reject'
                                              variant={'outlined'}
                                              color={'warning'}
                                              sx={{width: '140px'}}
                                              onClick={onRejectClick}>
                                          Reject
                                      </Button>

                                      <Button data-test='approve'
                                              variant={'contained'}
                                              fullWidth
                                              onClick={() => setMode(Mode.Approvement)}>
                                          Schedule meeting
                                      </Button>
                                  </Box>}
                              {currentCandidate?.status === PositionMatchStatus.CompanyDeclined &&
                                  <Typography sx={{fontWeight: 600}}>Rejected</Typography>}

                              {(!!currentCandidate.expectedRate) && <Box sx={{
                                background: '#F0F8FD',
                                border: '1px solid #DDE1E3',
                                borderRadius: '10px',
                                p: '20px 24px'
                              }}>
                                  <Typography variant={"body1"} fontSize={16} fontWeight={600}>Salary
                                      Requirement:</Typography>
                                  <Typography variant={"body1"}
                                              sx={{paddingTop: 1}}>{expectedSalaryFromPositionMatch(currentCandidate)}</Typography>
                              </Box>}

                              {(currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall && !hasContract) && (
                                  <Box sx={{mb: 2}}>
                                    <Button data-test='hire'
                                            sx={{width: 1,}}
                                            onClick={() => history.push('/hire/' + positionId + '/' + currentCandidate?.id)}
                                            variant="contained">
                                      Hire Contractor
                                    </Button>
                                  </Box>
                              )}

                              <MeetingList meetings={meetings}
                                           onScheduleClick={() => setMode(Mode.Approvement)}
                                           onCancelMeeting={onCancelMeeting}
                                           showNextMeetingButton={currentCandidate?.status === PositionMatchStatus.CompanyScheduledCall}
                                           positionMatchId={currentCandidate!.id}
                                           title={'Scheduled interviews with this candidate'} />
                            </>}
            />
          )}

          {mode === Mode.Approvement && (
            <Approval positionMatchId={currentCandidate!.id}
                      onScheduleClick={() => setMode(Mode.Approvement)}
                      onBackClick={() => setMode(Mode.Preview)}
                      onScheduled={() => {
                        refetch();
                        setMode(Mode.Preview);
                      }}/>
          )}
        </>}


      </Container>
    </Loading>
  </Box>
  </RescheduleMeetingClickHandlerContextProvider>
}

