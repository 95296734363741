import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import {client} from "./ApolloClient";
import {BrowserRouter} from "react-router-dom";
import {ApolloProvider} from "@apollo/client";
import {closeSnackbar, SnackbarProvider} from "notistack";
import {IconButton, ThemeProvider} from "@mui/material";
import Theme from "./AppTheme";
import {StoryBook} from "./storybook/StoryBook";

const root = createRoot(document.getElementById('root') as HTMLElement)

const Snackbar = SnackbarProvider as any;

const action = (snackbarId: any) => (
  <IconButton
    onClick={() => { closeSnackbar(snackbarId) }}
    sx={{'&:hover': {backgroundColor: 'rgba(255,255,255, 0.1)', borderRadius: '50%'}}}
  >
    <img src={'/img/teenyicons/x-white.svg'} width={16} height={16} />
  </IconButton>
);

if (process.env.REACT_APP_STORYBOOK === 'true') {
  root.render(
    <ThemeProvider theme={Theme}>
      <Snackbar preventDuplicate action={action} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <StoryBook />
          </BrowserRouter>
        </ApolloProvider>
      </Snackbar>
    </ThemeProvider>
  );
} else {
  root.render(
    <ThemeProvider theme={Theme}>
      <Snackbar preventDuplicate action={action} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </Snackbar>
    </ThemeProvider>
  );
}

