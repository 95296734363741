import React from "react";
import {PositionLocation} from "../../../api/sdl";
import {Box, Button, FormControl, FormLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {Minus, Plus} from "phosphor-react";
import {states} from "../../../shared";

export const LocationFormSection = React.forwardRef(({locations, onChange}: {
  locations: PositionLocation[],
  onChange: (locations: PositionLocation[]) => void
}, ref) => {
  const [array, setArray] = React.useState<(PositionLocation | undefined)[]>([{
    state: "Empty",
    city: ""
  }]);

  React.useEffect(() => {
    setArray(locations?.length ? locations : [{
      state: "Empty",
      city: ""
    }]);
  }, [locations]);

  React.useImperativeHandle(ref, () => ({
    getLocations: () => array.filter(v => !!v?.city || !!v?.state)  ,
  }));

  return <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
    <Box sx={{display: 'flex', gap: 2, width: 1, alignItems: 'baseline'}}>
      <Typography variant={'h4'}>Locations</Typography>
      <Box>
        <Button
          startIcon={<Plus size={18} color="#128eaf" weight="regular" />}
          variant="text"
          onClick={() => {
            setArray([...array.filter(v => !!v), undefined])
          }}
        >
          Add
        </Button>
      </Box>
    </Box>
    <Box sx={{marginLeft: '0'}}>
    {array.map((v, i) =>
      <Box sx={{display: 'flex', gap: 3, width: 1, alignItems: 'flex-end', marginBottom: 2}}  key={i}>
        <FormControl sx={{width: 1}}>
          <FormLabel>State</FormLabel>
          <Select value={array[i]?.state} data-test='state' onChange={(e) => {
            const newValue: PositionLocation = {state: e.target.value || '', city: array[i]?.city || ''};
            const result = [...array];
            result[i] = newValue;
            setArray(result)
          }}>
            {states.map(v => <MenuItem key={'st' + v.abbreviation } value={v.abbreviation}>{v.name}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{width: 1}}>
        {array[i]?.state !== "Empty" &&
            <>
              <FormLabel>Town / City</FormLabel>
              <TextField placeholder="Town / City"
                         data-test='town'
                         name="city"
                         disabled={!array[i]}
                         value={array[i]?.city || ""}
                         onChange={e => {
                           const result = [...array];
                           result[i]!.city = e.target.value as string;
                           setArray(result)
                         }}
              />
            </>
        }
        </FormControl>
      <Box sx={{width: '200px'}}>
        {i > 0 &&
          // disabled={!array[i]}

          <Button startIcon={<Minus size={18} color="#128eaf" weight="regular" />}
                  variant="text"
                  onClick={() => {
                    const result = [...array];
                    result.splice(i, 1);
                    setArray([]);
                    setTimeout(() => setArray(result.length ? result : [undefined]), 50)
                  }}
          >
            Remove
          </Button>
        }
      </Box>
      </Box>
    )}
    </Box>
  </Box>
})
