import {Box, Button, CircularProgress} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import {CaretLeft, CaretRight} from "phosphor-react";
import ClickableCalendarTable from "../../../feature/calendar/ClickableCalendarTable";
import {filterClickableDatesOnly} from "../lib/util";
import {CalendarSlot} from "../../../api/sdl";

interface Props {
  slots: CalendarSlot[],
  onClick: (date: DateTime) => void,
  loading: boolean,
  startDate: DateTime,
  onStartDateChange: (newDate: DateTime) => void,
  selectedDate?: DateTime,
}

export function AppointmentCalendar({onClick, slots, loading, startDate, onStartDateChange, selectedDate}: Props) {

  return <Box>

    {loading && <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '400px'}}>
        <CircularProgress size="15" />
    </Box>}

    {!loading && (
      <ClickableCalendarTable slots={slots}
                              startDate={startDate}
                              selectedDate={selectedDate}
                              onClick={(date: DateTime) => filterClickableDatesOnly(slots, date, onClick)}  />
    )}

    <Box sx={{display: 'flex', gap: 5, justifyContent: 'center', mt: 4}}>
      <Button sx={{fontWeight: 800}}
              variant={'text'}
              size={'small'}
              data-test='prev-week'
              onClick={() => onStartDateChange(startDate.minus({week: 1}))}
              startIcon={<CaretLeft size={16} color="#000" />}>
        Previous Week
      </Button>
      <Button sx={{fontWeight: 800}}
              variant={'text'}
              size={'small'}
              data-test='next-week'
              onClick={() => onStartDateChange(startDate.plus({week: 1}))}
              endIcon={<CaretRight size={16} color="#000" />}>
        Next Week
      </Button>
    </Box>

  </Box>
}