import {keycloak} from "../../keycloak";
import {client} from "../../ApolloClient";
import { gql } from "@apollo/client";

const menu = async () => {
  const notifications = client.query({
    query: gql`{myNotificationCounter}`,
    fetchPolicy: 'no-cache'
  })
  return {
    top: [
      {
        title: 'Notifications',
        route: '/notifications',
        icon: 'bell',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'My profile',
        route: '/profile',
        icon: 'users',
      },
      {
        title: 'Find Work',
        route: '/positions',
        icon: 'briefcase',
      },
      {
        title: 'My calendar',
        route: '/calendar',
        icon: 'appointments',
      },
      {
        title: 'Manage Payouts',
        route: '/settings',
        icon: 'credit-card',
      },
      {
        title: 'Payouts',
        route: '/payouts',
        icon: 'briefcase',
      },
    ],
    bottom: [
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => {
          localStorage.clear();
          keycloak.logout({redirectUri: document.location.origin})
        }
      }
    ]
  }
}

export default menu;