import {RouteComponentProps} from "react-router-dom";
import {
  Box, Container, Typography
} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import {useMyNotificationsQuery, Notification, ToDoStatus} from "../../api/sdl";
import Loading from "../../shared/components/Loading";
import {NotificationButton} from "./ui/NotificationButton";


function Notifications(props: RouteComponentProps<any>) {
  const [page, setPage] = React.useState(0);
  const {data, loading} = useMyNotificationsQuery({fetchPolicy: 'no-cache', variables: {
      page: {
        page,
        size: 200
      }
    }})

  const notifications = React.useMemo(() => {
    return data?.myNotifications.data || [];
  }, [data])

  return <Box>
    <Loading in={loading}>
      {!!notifications.length && <Container sx={{p: 3, my: {md: 10}, display: 'flex', flexDirection: 'column', gap: 1.5,}} maxWidth={'lg'}>
          <Typography variant={'body2'} sx={{mb: 1.5, ml: 1.5}}>{notifications.length} new notifications</Typography>
        {notifications.map((item, i) => <React.Fragment key={item.id}>
          <NotificationView item={item}  />
        </React.Fragment>)}
      </Container>}
      {!notifications.length && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
          <Typography>No notifications</Typography>
      </Box>}
    </Loading>
  </Box>;
}

export default Notifications;

const NotificationView = ({item}: {item: Notification}) => {

  return <Box data-test='notification' sx={{display: 'flex', background: '#fff', border: '1px solid #E4E4E4', borderRadius: '8px', alignItems: {xs: 'flex-start', md: 'center'}, gap: 3, p: '23px 28px 23px 20px', flexDirection: {xs: 'column', md: 'row'}}}>
    <Box flex={1}>
      <Typography sx={{color: '#2C92D5', fontWeight: 600, mb: 1}}>{item.title}</Typography>
      <Box sx={{display: 'flex', gap: .7}}>
        <Typography variant={'body2'} sx={{whiteSpace: 'nowrap'}}>{DateTime.fromISO(item.createdAt).toFormat('LLL d')}</Typography>
        <Typography variant={'body2'}>|</Typography>
        <Box sx={{'& p': {fontSize: 12, lineHeight: 1.5, color: 'text.secondary', m: 0}}} dangerouslySetInnerHTML={{__html: item.description}} />
      </Box>
    </Box>
    <Box>
      {item.toDoStatus === ToDoStatus.Completed && <Typography sx={{color: '#00B94A'}}>Complete</Typography>}
      {item.toDoStatus !== ToDoStatus.Completed && <>
          <NotificationButton item={item} />
      </>}
    </Box>
  </Box>
}
