import {DialogContent, DialogTitle, Divider, Drawer, IconButton, Paper} from "@mui/material";
import React, {useState} from "react";
import { RescheduleMeetingClickHandlerContext } from "src/entity";
import {ScheduleMeeting} from "../../../../widget";
import {usePositionMatchQuery} from "../../../../api/sdl";

export function CandidateMeetings(props: {positionMatchId: string, onClose: () => void}) {

  const [meetingId, setMeetingId] = useState<string>();
  const [positionMatchId, setPositionMatchId] = useState(props.positionMatchId);

  const {data} = usePositionMatchQuery({
    variables: {positionMatchId},
    skip: !meetingId,
    fetchPolicy: 'no-cache',
  })

  const onRescheduleClick = (positionMatchId: string, meetingId: string) => {
    setPositionMatchId(positionMatchId);
    setMeetingId(meetingId);
  };

  const meeting = data?.positionMatch.meetings.find(m => m.id === meetingId);

  return <Drawer
    open={!!positionMatchId}
    anchor={'right'}
    onClose={props.onClose}
    key={positionMatchId}
  >
    <Paper
      sx={{
        maxWidth: 1200,
        borderRadius: 'md',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
         Meeting Scheduler
        <IconButton
          onClick={props.onClose}
        >
          <img src={'/img/teenyicons/x.svg'} width={25} height={25} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ gap: 2, p: 2 }}>
        <RescheduleMeetingClickHandlerContext.Provider value={{handler: onRescheduleClick}}>
          <ScheduleMeeting positionMatchId={positionMatchId}
                           meeting={meeting}
                           onMeetingScheduled={() => {}}
                           meetingListTitle={'Interviews with this candidate'}
                           onScheduleClick={() => {}} />
        </RescheduleMeetingClickHandlerContext.Provider>
      </DialogContent>
    </Paper>
  </Drawer>
}