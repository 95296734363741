import {usePastMeetingsQuery} from "../../../../api/sdl";
import React from "react";

export default function useMeetingsForPosition(positionId: string) {
  const {data, loading} = usePastMeetingsQuery({fetchPolicy: 'no-cache', variables: {positionId, page: {page: 0, size: 20}}});

  return React.useMemo(() => {
    return data?.pastMeetings?.data || [];
  }, [data])
}
