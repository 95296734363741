import * as _ from 'lodash';
import {AttributeCategory, FileStorage} from "../api/sdl";
import config from "../config";
import {keycloak} from "../keycloak";
import {Candidate} from "../company/pages/Candidates/lib/types";

export function hasKeycloakRole(role: string) {
  const roles = keycloak?.tokenParsed?.realm_access?.roles || []
  return roles.includes(role);
}

export const getFilesUrl = (fileStorage?: FileStorage | null): string => (
  (fileStorage === FileStorage.Ovh ? config.filesUrl : config.s3FilesUrl) as string
)

export function scrollToFirstError(errors?: {[name: string]: string}) {
  if (errors) {
    scrollToElement(`[name="${Object.keys(errors)[0]}"]`)
  }
}

export function scrollToElement(selector: string) {
  const el = document.querySelector(selector);
  if (el) {
    el.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}





export const showRange = (v1?: string | number | null, v2?: string | number | null, additional?: string | JSX.Element, na?: string, callback?: (value: any) => any): string => {
  let result;
  const s = callback ? callback : (v: any) => v;
  if (v1 && !v2) {
    result = s(v1);
  } else if (!v1 && v2) {
    result = s(v2);
  } else if (!v1 && !v2) {
    return  na || `—`;
  } else if (v1 === v2) {
    result = s(v1);
  } else {
    result = s(v1) + ' - ' + s(v2);
  }

  return _.trim(`${result} ${additional || ''}`);
}

export function handleCollectionChange<T>(element: T, collection: T[]) {

  let out: T[];

  if (!!collection) {

    if (collection.includes(element)) {

      out = _.without(collection, element);

    } else {

      out = [...collection, element]
    }
  } else {

    out = [element];
  }

  return out
}

export const getCategoryColor = (category: AttributeCategory): any => {
  switch (category) {
    case AttributeCategory.CallPointSpeciality: return 'primary';
    case AttributeCategory.RoleCategory: return 'info';
    default: return 'secondary';
  }
}

export function toCurrency(value: number, minimumFractionDigits = 0, maximumFractionDigits = 0) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits }).format(value);
}

export const expectedSalaryFromPositionMatch = (c: Candidate, multiplier: number = 1) => {
  return `${toCurrency(c.expectedRate * multiplier)} ${_.startCase(c?.position?.salaryRangeType?.toLowerCase())}`
}

export function toFirstCase(value?: string) {
  return _.startCase(_.lowerCase(value || ''))
}

export const states = [
  {
    "name": "No specific market",
    "abbreviation": "Empty"
  },
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
]

export const fullCommissionMultiplier = (platformCommissionInPercents: number) => {
  return (100 + platformCommissionInPercents) / 100
}

export const platformCommissionMultiplier = (platformCommissionInPercents: number) => {
  return platformCommissionInPercents / 100
}