import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  Box, Container, Typography, IconButton, Button
} from "@mui/material";
import React from "react";
import {
 useAdminContractsQuery,
 useUpdateInvoicesFromHarvestMutation
} from "../../../../api/sdl";
import Loading from "../../../../shared/components/Loading";
import { PlusCircle } from "phosphor-react";
import { enqueueSnackbar } from "notistack";
import {LoadingButton} from "@mui/lab";
import AdminContractListItem from "./ui/AdminContractListItem";


function Index(props: RouteComponentProps<{positionId?: string}>) {

  const contractsQuery = useAdminContractsQuery({fetchPolicy: 'no-cache'});
  const history = useHistory();

  const contractData = React.useMemo(() => {
    return contractsQuery.data?.adminContracts|| [];
  }, [contractsQuery.data])

  const [update, updateData] = useUpdateInvoicesFromHarvestMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: () => {
      enqueueSnackbar('Invoices was updated', {variant: 'success'});
      contractsQuery.refetch();
    },
  })

  return <div>
    <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Typography variant={'h3'} sx={{ml: 2}}>Contracts</Typography>
        <IconButton sx={{borderRadius: '50%'}}
                    size={'small'}
                    onClick={() => history.push('/contract')}>
          <PlusCircle size={24} color={"#000000"} />
        </IconButton>
      </Box>
      <LoadingButton size={'small'} variant={'contained'} onClick={() => update()} loading={updateData.loading}>
        Update from Harvest
      </LoadingButton>
    </Box>
    <Container sx={{mt: 3}} maxWidth={'lg'}>
      <Loading in={contractsQuery.loading}>
        {contractData.map((item, i) =>
          <AdminContractListItem key={i} item={item} onClick={() => history.push('/contract/' + item.id)}/>
        )}
      </Loading>
    </Container>
  </div>;
}

export default Index
