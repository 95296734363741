import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {ArrowLeft} from "phosphor-react";
import {ScheduleMeeting} from "../../widget";

interface ApprovalProps {
  positionMatchId: string,
  onBackClick: () => void,
  onScheduled: () => void,
  onScheduleClick: () => void
}

function Approval(props: ApprovalProps) {
  const header = <Box sx={{my: 2}}>
    <Button variant={'text'} startIcon={<ArrowLeft size={20} color="#3075B8" weight="regular"/>}
            onClick={props.onBackClick}>Back to profile</Button>
    <Typography sx={{fontWeight: 700, fontSize: 18, my: 2}}>Schedule interview</Typography>
    <Typography>Use this calendar to schedule an interview. Once you pick a time, you will be sent a calendar
      invite via email. If none of the times below work for you, please email your MDliaison Account Manager to
      schedule a time.</Typography>
  </Box>

  return <ScheduleMeeting positionMatchId={props.positionMatchId}
                          onMeetingScheduled={props.onScheduled}
                          onScheduleClick={props.onScheduleClick}
                          meetingListTitle={'Other interviews with this candidate'}
                          slots={{
                            header,
                          }} />
}

export default Approval
