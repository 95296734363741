import {AttributeCategory, MeetingDetailsOutput, PositionLocation, PositionOutput, PositionStatus} from "../../api/sdl";
import {Box, Button, Card, Chip, Divider, FormLabel, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {Pencil} from "phosphor-react";
import {DateTime} from "luxon";
import {showRange, toCurrency} from "../../shared/utils";
import * as _ from "lodash";
import AttributesChips from "../../shared/components/AttributesChips";
import Param from "../../shared/components/Param";
import {useHistory} from "react-router-dom";
import useIsCompany from "../../shared/hooks/useIsCompany";
import TableRow from "../../shared/components/TableRow";
import {candidateLink} from "../../entity/meeting/lib/utils";
import {PositionStatusView} from "./ui/PositionStatusView";
import {formatLocation} from "../../entity/position/lib/util";

interface PositionViewProps {
  position: PositionOutput,
  onEditClick?: () => void,
  extraAction?: JSX.Element,
  onCandidatesClick?: () => void,
  nextInterview?: MeetingDetailsOutput,
}

const PositionView = ({position, onEditClick, onCandidatesClick, extraAction, nextInterview}: PositionViewProps) => {
  const history = useHistory();

  const isCompany = useIsCompany()

  return <Box>
    <PositionStatusView position={position} />

    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography sx={{fontSize: 34, lineHeight: '48px', fontWeight: 600, mb: 1}}>{position?.title}</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
        {extraAction && <>{extraAction}</>}
        {onEditClick && <IconButton
          size="small"
          sx={{borderRadius: '50%'}}
          onClick={onEditClick}
        >
          <Pencil size={20} color="#37c9ef" weight="light" />
        </IconButton>}
      </Box>
    </Box>



    {isCompany && <Box data-test='box-candidates' sx={{display: 'flex', alignItems: 'center', gap: 3}}>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'space-between', p: '16px 24px', borderRadius: '10px', background: '#F0F8FD', flex: 1}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 48, background: '#37C9EF', borderRadius: '24px'}}>
            <Box component={'img'} src={`/img/teenyicons/users-blue.svg`}  width={24} height={24} />
          </Box>
          <div>
            <Typography  sx={{fontWeight: 600, mb: .2}} data-test={"candidate-number-total"}>{position?.numberOfCandidates}</Typography>
            <Typography >Candidates</Typography>
          </div>
          <div>
            <Typography  sx={{ fontWeight: 600, mb: .2}} data-test={"candidate-number-for-review"}>{position?.candidatesForReview}</Typography>
            <Typography >For review</Typography>
          </div>
        </Box>

        {!!parseFloat(position?.numberOfCandidates || '0') && <Typography  onClick={() => history.push('/candidates/' + position?.id)} sx={{fontWeight: 600, color: '#3075B8', cursor: 'pointer', textDecoration: 'underline'}}>View candidates</Typography>}
      </Box>

      <Box sx={{display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'space-between', p: '16px 24px', borderRadius: '10px', background: '#F0F8FD', flex: 1}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, background: '#37C9EF', borderRadius: '24px'}}>
            <Box component={'img'} src={`/img/teenyicons/appointments-blue.svg`}  width={24} height={24} />
          </Box>
          {nextInterview && <div>
            <Typography  sx={{fontWeight: 600, mb: .2}}>{DateTime.fromISO(nextInterview.date).toFormat('LLL d, h:mm a')} - {DateTime.fromISO(nextInterview.date).plus({hour: 1}).toFormat('h:mm a')}</Typography>
            <Typography onClick={() => history.push(candidateLink(false, {positionId: position.id, contractorId: nextInterview!.contractorId}))} sx={{cursor: 'pointer', textDecoration: 'underline'}}>Candidate {nextInterview.candidateNumber}</Typography>
          </div>}
          {!nextInterview && <Typography  sx={{fontWeight: 600}}>No upcoming interviews</Typography>}
        </Box>
      </Box>
    </Box>}

    <Box sx={{display: 'flex', gap: '5vw', mt: 5}}>
      <Box sx={{width: 1, '& p': {lineHeight: 1.5}}} dangerouslySetInnerHTML={{__html: position?.description || ''}} />
      <Box sx={{minWidth: 320}}>

        <PositionViewJobDetails position={position} />

        <PositionViewCurrentExperience position={position} />

      </Box>
    </Box>


    {onCandidatesClick && <div>
      <Button disabled={!parseFloat(position.numberOfCandidates || '0')} onClick={onCandidatesClick} variant={'outlined'}>Candidates - {position.numberOfCandidates || 0}</Button>
    </div>}
  </Box>
}

export default PositionView;

export const PositionViewCurrentExperience = React.memo(({position}: {position?: PositionOutput}) => (
    <Box sx={{background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px'}}>
      <Stack spacing={3}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between', width: 1}}>
          <Typography sx={{color: '#8A8C8D', fontWeight: 500}}>Current experience</Typography>
        </Box>
        <AttributesChips sx={{ mt: 1, maxWidth: 200 }} color={'info'} attributes={position?.attributesExpanded.filter(a => a.category === AttributeCategory.RoleCategory).map(a => a.attribute)} />
        {!!position?.attributesExpanded.filter(a => a.category === AttributeCategory.CallPointSpeciality)?.length && <>
          <Divider />
          <Param title={'Call point specialities'} content={<AttributesChips sx={{ mt: 1, maxWidth: 200 }} color={'info'} attributes={position?.attributesExpanded.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.attribute)} />}/>
        </>}
        <Divider />
        <Param title={'Salary'} content={<Typography sx={{color: '#8A8C8D', fontWeight: 500}}>{showRange(position?.salaryRangeMin, position?.salaryRangeMax, undefined, undefined, toCurrency)} {!!position?.salaryRangeType && _.startCase(_.toLower(position?.salaryRangeType))}</Typography>}/>
      </Stack>
    </Box>
))

export const PositionViewJobDetails = React.memo(({position}: {position?: PositionOutput}) => (
  <Box sx={{background: 'white', border: '1px solid #DDE1E3', borderRadius: '10px', p: '20px 24px', mb: 2}}>
    <Typography sx={{color: '#8A8C8D', fontWeight: 500, mb: 3}}>Job details</Typography>
    <Stack spacing={2.5}>
      <Box sx={{display: 'table'}}>
        <TableRow image={`/img/teenyicons/pin.svg`}
                  title={'Location:'}
                  content={<>
                    {position?.locations.map((l, i) => <Typography key={i} sx={{color: 'text.primary'}} >{formatLocation(l)}</Typography>)}
                  </>}
        />

        {!!position?.attributesExpanded?.filter(a => a.category === AttributeCategory.ExpectedContractLength).length
          &&

          <TableRow image={`/img/teenyicons/hourglass.svg`}
                    title={'Expected length:'}
                    content={<>
                      {position?.attributesExpanded
                        .filter(a => a.category === AttributeCategory.ExpectedContractLength)
                        .map(a =>
                        <Typography key={a.id} sx={{color: 'text.primary'}}>{a.attribute}</Typography>)}
                    </>}
          />
        }

        <TableRow image={`/img/teenyicons/hourglass.svg`}
                  title={'Hours / week:'}
                  content={<Typography sx={{color: 'text.primary'}} >{formatHoursPerWeek(position!!)}</Typography>}
        />

        <TableRow image={`/img/teenyicons/money.svg`}
                  title={'Salary range:'}
                  content={<Typography sx={{color: 'text.primary'}} >{showRange(position?.salaryRangeMin, position?.salaryRangeMax, undefined, undefined, toCurrency)} {!!position?.salaryRangeType && _.startCase(_.toLower(position?.salaryRangeType))}</Typography>}
        />

        <TableRow image={`/img/teenyicons/money.svg`}
                  title={'Commission or bonus:'}
                  content={<Typography sx={{color: 'text.primary'}} >{position?.commissionBonus ? position?.commissionBonus : "—"}</Typography>}
        />

        <TableRow image={`/img/teenyicons/appointments-gray.svg`}
                  title={'Date of start:'}
                  content={<Typography sx={{color: 'text.primary'}} >{position?.preferableStartDate ? DateTime.fromFormat(position?.preferableStartDate, 'yyyy-LL-dd').toFormat('LLL dd, yyyy') : ''}</Typography>}
        />
      </Box>
    </Stack>
  </Box>
))

function formatHoursPerWeek(position: PositionOutput) {

  if(position.hoursPerWeekMin == position.hoursPerWeekMax || !position.hoursPerWeekMax) {
    return position.hoursPerWeekMin
  } else {
    return `${position.hoursPerWeekMin} - ${position.hoursPerWeekMax}`
  }
}