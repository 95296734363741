import React, {useContext} from "react";
import {
  RouteComponentProps,
  Redirect,
  Route,
  Switch, useHistory,
} from 'react-router-dom';

import {Box, Typography} from "@mui/material";
import AdminApp from "./admin/AdminApp";
import ContractorApp from "./contractor/ContractorApp";
import CompanyApp from "./company/CompanyApp";
import {withConfirmDialog} from "./shared/ConfirmDialogContext";
import {KeycloakContext, KeycloakRoles, withKeycloakContext} from "./keycloak";
import MeetingCardPage from "./shared/pages/MeetingCardPage";
import _ from "lodash";
import {StartRedirect} from "./feature/start-redirect";


function App(props: RouteComponentProps<{}>) {
  const {initialized, keycloak} = useContext(KeycloakContext);
  const history = useHistory();

  const role = React.useMemo(() => {
    if (initialized && keycloak?.authenticated) {
      const roles = keycloak?.tokenParsed?.realm_access?.roles || [];

      if (roles.includes('ROLE_ADMIN')) {
        return KeycloakRoles.admin;
      }
      if (roles.includes('ROLE_COMPANY')) {
        return KeycloakRoles.company;
      }

      if(roles.includes('ROLE_CONTRACTOR')) {
        return KeycloakRoles.contractor;
      }

      throw `Unknown roles: ${roles}`
    }

    return undefined
  }, [initialized, keycloak?.authenticated])

  React.useEffect(() => {
    if (role && (
      document.location.pathname.indexOf('/interview-meeting') !== 0
    )) {
      if (role === KeycloakRoles.admin) {
        if (document.location.pathname.indexOf('/admin') !== 0) {
          history.push('/admin')
        }
      } else if (role === KeycloakRoles.company) {
        if (document.location.pathname.indexOf('/company') !== 0) {
          history.push('/company')
        }
      } else if (document.location.pathname.indexOf('/contractor') !== 0) {
        history.push('/contractor')
      }
    }
  }, [role])

  React.useEffect(() => {
    (window as any).mixpanel.init('b028db252221b4a9816e917410c8cb19', { persistence: 'localStorage' });
  }, [])

  /*if (!initialized) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <CircularProgress size="15" />
    </Box>
  }*/

  if (process.env.REACT_APP_CLOSED === 'true') {
    return <Box sx={{width: '100vw', height: '100vh', p: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Typography sx={{textAlign: 'center', fontWeight: 600}}>{process.env.REACT_APP_CLOSED_MESSAGE}</Typography>
    </Box>
  }

  return <div>
    {process.env.REACT_APP_CLOSED_MESSAGE && <Box sx={{width: 1, zIndex: 5, p: '4px 16px', background: '#b50707'}}>
      <Typography sx={{textAlign: 'center', fontWeight: 600, color: 'white'}}>{process.env.REACT_APP_CLOSED_MESSAGE}</Typography>
    </Box>}
    <Box sx={{pt: process.env.REACT_APP_CLOSED === 'true' ? 3 : 0}}>
      <Switch>
        <Route path={'/interview-meeting/:meetingId'} component={MeetingCardPage}/>
        <Route path={'/start'} component={StartRedirect}/>
        <Route path={'/interview-meeting-v2/:meetingId'} component={MeetingCardPage}/>
        <Route path={'/company/:routeName?'} component={CompanyApp}/>
        <Route path={'/contractor/:routeName?'} component={ContractorApp}/>
        <Route path={'/admin/:routeName?'} component={AdminApp}/>
        <Route exact path={'/'}>
          <Redirect to={'/contractor'} />
        </Route>
        <Route>
          <Redirect to={'/contractor'} />
        </Route>
      </Switch>
    </Box>
  </div>;
}

export default _.flowRight(withKeycloakContext, withConfirmDialog)(App);
