import {useHistory} from "react-router-dom";
import {Box, FormControl, FormHelperText, FormLabel, TextField,} from "@mui/material";
import * as React from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import HarvestClientSelect from "../../../../components/HarvestClientSelect";
import HarvestProjectSelect from "../../../../components/HarvestProjectSelect";
import {useSetupContractMutation, WorkContractOutput, WorkContractStatus} from "../../../../../api/sdl";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";

const schemaFields: any = {
  harvestClientId: yup
    .string()
    .required('Client is required'),
  harvestProjectId: yup
    .string()
    .required('Project is required'),
  anticipatedStartDate: yup
    .date()
    .typeError('Date is required')
    .required('Date is required')
}

interface Props {
  contract: WorkContractOutput
}

function EditContractForm({contract}: Props) {
  const history = useHistory();
  const [save, {loading}] = useSetupContractMutation()

  const formik = useFormik({
    initialValues: {
      harvestClientId: contract.harvestClientId || '',
      harvestProjectId: contract.harvestProjectId || '',
      anticipatedStartDate: contract.anticipatedStartDate ? DateTime.fromISO(contract.anticipatedStartDate).toJSDate(): null,
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        save({variables: {contractId: contract.id, payload: {
          ...values,
          anticipatedStartDate: DateTime.fromJSDate(values.anticipatedStartDate!).toFormat('yyyy-LL-dd')
        }}})
          .then((res) => {
            enqueueSnackbar('Contract was updated successfully', {variant: 'success'});
            history.push('/contracts')
          })
      }
    },
    validateOnChange: true
  });

  return <Box ml={2} maxWidth={500} component={'form'} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1, position: 'relative'}}>
      <HarvestClientSelect value={formik.values['harvestClientId']}
                           disabled={contract.status !== WorkContractStatus.Setup}
                           onChange={value => formik.setFieldValue('harvestClientId', value)}
                           error={formik.touched.harvestClientId && formik.errors.harvestClientId ? formik.errors.harvestClientId as string : undefined}
      />
      <HarvestProjectSelect value={formik.values['harvestProjectId']}
                            disabled={contract.status !== WorkContractStatus.Setup}
                            onChange={value => formik.setFieldValue('harvestProjectId', value)}
                            error={formik.touched.harvestProjectId && formik.errors.harvestProjectId ? formik.errors.harvestProjectId as string : undefined}
                            clientId={formik.values['harvestClientId']}
      />
      <FormControl>
        <FormLabel>Anticipated Start Date</FormLabel>
        <DatePicker selected={formik.values.anticipatedStartDate}
                    customInput={<TextField placeholder="Select date"
                                            data-test='start-date'
                                            error={formik.touched.anticipatedStartDate && Boolean(formik.errors.anticipatedStartDate)}
                    />}
                    minDate={new Date()}
                    onChange={(date: Date) => formik.setFieldValue('anticipatedStartDate', date)} />
        {(formik.touched.anticipatedStartDate && formik.errors.anticipatedStartDate) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.anticipatedStartDate}</FormHelperText>}
      </FormControl>
      <div>
        <LoadingButton loading={loading} variant={'contained'} disabled={loading || contract.status !== WorkContractStatus.Setup} type={'submit'}>Submit</LoadingButton>
      </div>
    </Box>
  </Box>
}

export default EditContractForm
