import {useSnackbar} from "notistack";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {useCancelMeetingMutation} from "../../../api/sdl";

export function useCancelMeeting(callback: () => void) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [cancel] = useCancelMeetingMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Meeting was canceled successfully', {variant: 'info'});
      callback && callback()
    },
  })

  return (meetingId: string) => confirm({
    content: 'Are sure you want to cancel this meeting?',
    okButtonTitle: 'Yes - I want to cancel this meeting',
    cancelButtonTitle: 'No - return',
    width: 500
  }).then(val => val && cancel({variables: {meetingId}}));
}