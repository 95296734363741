import {useEffect, useState} from "react";
import {useGetContractByCompanyAndContractorLazyQuery} from "../../../../api/sdl";

export default function useCandidateHasContract(contractorId?: string, companyId?: string) {
  const [hasContract, setHasContract] = useState(true)
  const [getHasContract] = useGetContractByCompanyAndContractorLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: res => setHasContract(!!res.getContractByCompanyAndContractor)
  })

  useEffect(() => {
    if (contractorId) {
      setHasContract(true);
      getHasContract({variables: {contractorProfileId: contractorId, companyId}})
    }
  }, [contractorId]);

  return hasContract;
}
