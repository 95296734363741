import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import React from "react";
import {ContractorCard} from "../../../widget";
import {
  ContractorSearchPayloadInput,
  ContractorStatus,
  useAddCandidateMutation,
  useCancelMeetingMutation,
  useCandidatesByPositionQuery,
  useConfirmContractorApplicationMutation,
  useContractorSearchQuery,
  useRemoveCandidateMutation,
} from "../../../api/sdl";
import ContractorFilter from "../ContractorFilter";
import useSessionStorageState from "../../../shared/hooks/useSessionStorageState";
import {useSnackbar} from "notistack";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {LoadingButton} from "@mui/lab";
import {ShortList} from "./ui/ShortList";
import {CandidateMeetings} from "./ui/CandidateMeetings";

const CandidatesList = ({positionId, candidateId, companyId}: {companyId: string, positionId: string, candidateId?: string}) => {
  const [filter, setFilter] = useFilter(positionId);
  const { enqueueSnackbar } = useSnackbar();
  const [positionMatchId, setPositionMatchId] = React.useState<string>();

  const [addCandidate, addCandidateData] = useAddCandidateMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Candidate was added successfully', {variant: 'success'});
    },
  });
  const [removeCandidate, removeCandidateData] = useRemoveCandidateMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Candidate was removed successfully', {variant: 'info'});
    },
  });
  const [confirmContractor] = useConfirmContractorApplicationMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Candidate was confirmed successfully', {variant: 'success'});
    },
  })
  const confirm = useConfirm();

  const [cancel] = useCancelMeetingMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Meeting was canceled successfully', {variant: 'info'});
    },
  })

  const onCancelClick = (meetingId: string) => confirm({
    content: 'Are sure you want to cancel this meeting?',
    okButtonTitle: 'Yes - I want to cancel this meeting',
    cancelButtonTitle: 'No - return',
    width: 500
  }).then(val => val && cancel({variables: {meetingId}}).then(() => refetchCandidates()));

  const {data: candidatesData, refetch: refetchCandidates} = useCandidatesByPositionQuery({fetchPolicy: 'no-cache', variables: {positionId}})

  const [page, setPage] = React.useState(0);
  const {loading: searchLoading, data} = useContractorSearchQuery({
    fetchPolicy: 'no-cache',
    variables: {
      payload: {...filter, status: ContractorStatus.Approved},
      page: {
        size: 10,
        page
      }
    },
  });

  const onFilterChange = (value: ContractorSearchPayloadInput) => {
    setPage(0);
    setFilter(value)
  }

  const onShortLitClick = (id: string) => {
    addCandidate({variables: {payload: {
          contractorId: id,
          positionId
        }
      }}).then(() => refetchCandidates())
  }

  const onRemoveShortListClick = (id: string) => {
    removeCandidate({variables: {id}}).then(() => refetchCandidates())
  }

  const candidates = candidatesData?.candidatesByPosition || []

  const contractors = data?.contractorSearch?.data || []

  React.useEffect(() => {
    const contractorId = contractors.find(c => candidateId === c.contractorProfile?.contractorId)?.contractorProfile?.contractorId;
    if (candidateId && contractorId) {
      setTimeout(() => {
        document.getElementById(contractorId)?.scrollIntoView()
      }, 300)
    }
  }, [contractors, candidateId])

  const isCandidate = (contractorId: string): boolean => {
    return !candidates.every(ca => ca.contractorProfile?.contractorId !== contractorId)
  }

  const onScheduleClick = (id: string) => {
    setPositionMatchId(id)
  }

  const handleConfirmClick = async (positionMatchId: string) => {
    await confirmContractor({variables: {positionMatchId}});
    await refetchCandidates();
  }
  return <div>
    {!!positionMatchId && (
      <CandidateMeetings positionMatchId={positionMatchId}
                         onClose={() => {
                           setPositionMatchId(undefined)
                         }}/>
    )}
    <Box sx={{mb: 2, display: 'flex', justifyContent: 'space-between'}}>
      <Typography variant={'h3'} sx={{ml: 2}}>Candidate list</Typography>
      <ContractorFilter filter={filter} onChange={onFilterChange} hideStatus />
    </Box>
    <Box sx={{display: 'flex', gap: 2, ml: 2, mb: 10, minHeight: '100vh'}}>
      <Box flex={2}>
        <Stack spacing={5}>
          {contractors.map((c, i) => <div data-test='list' id={c.contractorProfile?.contractorId} key={i}>
            <Box sx={{m: '-24px'}}>
              <ContractorCard profile={c.contractorProfile || undefined}
                              userProfile={c.profile || undefined}
                              showSideStripe={false}/>
            </Box>
            <Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
              {!isCandidate(c!.contractorProfile?.contractorId) && (
                <LoadingButton loading={addCandidateData.loading}
                               variant='contained'
                               data-test={`short-list-${c.contractorProfile?.contractorId}`}
                               onClick={() => onShortLitClick(c!.contractorProfile?.contractorId)}>Short list</LoadingButton>
              )}
              {isCandidate(c!.contractorProfile?.contractorId) && (
                <LoadingButton loading={removeCandidateData.loading}
                               variant='contained'
                               color='error'
                               onClick={() => onRemoveShortListClick(candidates.find(ca => ca.contractorProfile?.contractorId === c!.contractorProfile?.contractorId)!.id)}>Remove
                  from short list</LoadingButton>
              )}
            </Box>
          </div>)}
        </Stack>
        {searchLoading && <Box sx={{mt: 3, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress size="15" />
        </Box>}
        {(!data?.contractorSearch?.isLast && !searchLoading) && <Box sx={{mt: 3, display: 'flex', justifyContent: 'center'}}>
          <Button variant={'outlined'} onClick={() => setPage(p => p + 1)}>Load more</Button>
        </Box>}
      </Box>

      <Box flex={1} sx={{minWidth: '300px'}}>
        <Box sx={{position: 'sticky', top: 20}}>
          <Typography variant={'h3'} sx={{ml: 2, mb: 2}}>Selected candidates</Typography>
          <Box sx={{overflowY: 'auto', mr: '-16px', maxHeight: 'calc(100vh - 70px)'}}>
            <ShortList candidates={candidates}
                       onRemoveClick={onRemoveShortListClick}
                       onConfirmClick={handleConfirmClick}
                       onInterviewsClick={setPositionMatchId} />
          </Box>
        </Box>
      </Box>
    </Box>

  </div>
}

export default CandidatesList;

function useFilter(id: string) {
  return useSessionStorageState<ContractorSearchPayloadInput>(
    'contractorPositionListFilter' + id,
    {attributes: [], state: null, term: ''}
  )
}
