import {Box, CircularProgress, List, Divider, ListItemButton, Typography} from "@mui/material";
import {FileStorage, InterviewAnswer} from "../../api/sdl";
import React from "react";
import {getFilesUrl} from "../utils";

export default function IntroductionVideoView({answers, loading}: {loading: boolean, answers?: Partial<InterviewAnswer>[]}) {
  const [index, setIndex] = React.useState(0);
//  const [blob, setBlob] = React.useState<Map<string, Blob>>(new Map<string, Blob>());

  const onChangeIndex = async (newIndex: number, play?: boolean) => {
    if (answers && answers.length && answers[newIndex]) {
      const newVal = answers[newIndex];
      setIndex(newIndex);

      const key = newVal.url;

      if (key) {
        let video = document.querySelector("#video-play");
        /*let data: Blob;

        if (blob.has(key)) {
          data = blob.get(key)!;
        } else {
          data = await fetch(config.filesUrl + '/' + key).then(res => res.blob())
          setBlob(prev => prev.set(key, data))
        }

        (video as any).src = URL.createObjectURL(data);*/
        (video as any).src = getFilesUrl(newVal.fileStorage) + '/' + key;
        (video as any).load();
        if (play) {
          (video as any).play();
        }
      }

      /*if (newIndex + 1 < answers.length && (answers[newIndex + 1] as any).url && !blob.has((answers[newIndex + 1] as any).url)) {
        const next = await fetch(config.filesUrl + '/' + (answers[newIndex + 1] as any).url).then(res => res.blob())
        setBlob(prev => prev.set((answers[newIndex + 1] as any).url, next));
      }*/
    }
  }

  React.useEffect(() => {
    if (answers) {
      onChangeIndex(0)
    }
  }, [answers])


  return <Box sx={{display: 'flex', flexDirection: {xs: 'column-reverse', md: 'row'}, gap: 3, position: 'relative'}}>
    {loading && <Box sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffffa3', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size="15" />
    </Box>}
    {(!loading && !answers?.length) && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1, minHeight: 200}}>
      <Typography >No videos available</Typography>
    </Box>}
    {!!answers?.length && <>
      <Box flex={2} maxWidth={350}>
        <List sx={{maxHeight: 480, overflowY: 'auto', pr: 1}}>
          {answers?.map(((a, i, arr) => <React.Fragment key={i}>
            <ListItemButton sx={{borderRadius: '8px', p: 2}} onClick={() => onChangeIndex(i, true)} color={'primary'}>
              <Typography  sx={{color: '#000', overflow: 'hidden', whiteSpace: {md: i === index ? 'normal' : 'nowrap'}, maxWidth: 300, textOverflow: 'ellipsis'}}>{a.videoQuestion!.title}</Typography>
            </ListItemButton>
            {i < arr.length - 1 && <Divider sx={{my: 1}} />}
          </React.Fragment>))}
        </List>
      </Box>
      <Box flex={3}>
        <Box component={'video'} onEnded={() => {onChangeIndex(index + 1, true)}}
             controls sx={{width: 1, borderRadius: '16px'}} id="video-play" autoPlay={false} style={{display: 'block'}}></Box>
      </Box>
    </>}
  </Box>
}
