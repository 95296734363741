import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {AwardForm} from "./ui/AwardForm";
import {AwardData} from "./lib/types";
import {AwardList} from "./ui/AwardList";
import {ProfileContext} from "../../shared/ProfileContext";

interface Props {
    editable?: boolean,
    awards?: AwardData[],
}
export function ContractorAwards({editable, awards}: Props): JSX.Element {

    const [editing, setEditing] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const [profile, refresh] = React.useContext(ProfileContext);

    const handleEditClick = () => {
        if(editing) {
            setAdding(false)
        } else {
            awards?.length === 0 && setAdding(true);
        }
        setEditing(v => !v)
    }

    const handleFormCancel = () => {
        if(awards?.length === 0) {
            setEditing(false);
            setAdding(false);
        } else {
            setEditing(true);
            setAdding(false);
        }
    }

    const handleUpdate = () => {
        setEditing(true);
        setAdding(false);
        refresh();
    }

    return <Box>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-start', gap: 2, mb: 1, justifyContent: 'space-between'}}>

            <Typography sx={{fontWeight: 700, fontSize: 24}}>Awards and Recommendations</Typography>
            <Box sx={{width: {xs: '100%', sm: '180px' }, justifyContent: editing? 'space-between': 'flex-end', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>

                {editing &&
                    <Button onClick={() => setAdding(true)} variant={'outlined'} size={'small'}>Add new</Button>
                }

                {editable && <Button size={'small'} variant={'outlined'} onClick={handleEditClick}>
                    {editing ? "Preview" : "Edit"}
                </Button>}
            </Box>
        </Box>

        {editing && adding &&
            <Box>
                <AwardForm onUpdate={handleUpdate} onCancel={handleFormCancel}/>
            </Box>
        }

        {!adding &&
            <AwardList awards={awards} editing={editing} onUpdate={handleUpdate} />
        }
    </Box>
}