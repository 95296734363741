import React from "react";
import {Box, Button, Divider, IconButton, List, ListItem, Typography} from "@mui/material";
import {X} from "phosphor-react";
import {expectedSalaryFromPositionMatch, toCurrency} from "../../../../shared";
import * as _ from "lodash";
import {CandidatesByPositionQuery, PositionMatchStatus} from "../../../../api/sdl";

interface Props {
  candidates: CandidatesByPositionQuery['candidatesByPosition'],
  onRemoveClick: (id: string) => void,
  onConfirmClick: (id: string) => void,
  onInterviewsClick: (id: string) => void,
}

export function ShortList({candidates, onRemoveClick, onConfirmClick, onInterviewsClick}: Props) {
  return <List>
    {candidates.map((c, i, arr) => <React.Fragment key={i}>
      <ListItem secondaryAction={
        <IconButton size="small"
                    data-test='remove'
                    onClick={() => onRemoveClick(c.id)}>
          <X size={16} color="#A10E25" weight="regular" />
        </IconButton>
      }
      >
        <Box data-test='selected'>
          <Typography sx={{cursor: 'pointer'}} onClick={() => document.getElementById(c.contractorId)?.scrollIntoView()}>
            <b>{c.firstName} {c.lastName} #{c.candidateNumber}</b>
          </Typography>

          {c.expectedRate && <Typography variant={'body2'} sx={{color: 'text.primary', fontSize: '14px'}}>
              Salary Requirement: {expectedSalaryFromPositionMatch(c)}
          </Typography>}

          <Typography variant={'body2'} sx={{color: 'text.primary', fontSize: '14px'}}>{c.email}</Typography>
          <Typography sx={{color: 'text.secondary'}}>{_.upperFirst(_.lowerCase(c.status!!))}</Typography>
          {c.status === PositionMatchStatus.ContractorAppliedToPosition && (
            <Button color={'primary'}
                    variant={"contained"}
                    data-test={`confirm-${c.contractorId}`}
                    sx={{mt: 1}}
                    size={'small'}
                    onClick={() => onConfirmClick(c.id)}>Confirm</Button>
          )}
          <Box sx={{cursor: 'pointer', mt: 1}} >
            <Button data-test='schedule'
                    color={'primary'}
                    onClick={() => onInterviewsClick(c.id)}
                    size={'small'}
            >Interviews</Button>
          </Box>
        </Box>
      </ListItem>
      {i < arr.length - 1 && <Divider sx={{my: 1}} />}
      {i === arr.length - 1 && <Box sx={{my: 5}} />}
    </React.Fragment>)}
  </List>
}