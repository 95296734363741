import * as React from "react";
import {Box, Button, Typography} from "@mui/material";
import AttributesChips from "../../../shared/components/AttributesChips";
import {AttributeCategory, EmploymentHistory} from "../../../api/sdl";
import {formatEmploymentHistoryPeriod, getMonthName} from "../lib";

interface ViewProps {
  history: Partial<EmploymentHistory>,
  editable?: boolean,
  onEdit?: () => void,
  index: number,
}

export function EmploymentHistoryView({history, onEdit, index, editable = false}: ViewProps) {
  const duration = React.useMemo(() => {
    return formatEmploymentHistoryPeriod(history.startDateYear, history.startDateMonth, history.endDateYear, history.endDateMonth, history.currentlyIsWorking);
  }, [history]);

  const [collapsed, setCollapsed] = React.useState(true);

  return <div>
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Box sx={{flexGrow: '1'}}>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
          <Typography sx={{fontWeight: 700}} data-test={`eh-title-company-${index}`}>{history.title} at {history.companyName}</Typography>
          <AttributesChips color={'primary'} attributes={history.attributesExpanded?.filter(a => a.category === AttributeCategory.RoleCategory).map(a => a.attribute)} />
        </Box>
        <Typography sx={{color: '#6D6D6D'}} data-test={`eh-dates-${index}`}>
          {getMonthName(history.startDateMonth!, 'short')} {history.startDateYear} - {history.currentlyIsWorking ? 'now' : getMonthName(history.endDateMonth!, 'short') + ' ' + history.endDateYear} ({duration})
        </Typography>
        <Typography  sx={{color: '#6D6D6D', mb: 2}} data-test={`eh-location-${index}`}>
          {history.location} - {history.attributesExpanded?.filter(a => a.category === AttributeCategory.EmploymentType).map(a => a.attribute).join(', ')}
        </Typography>
        <AttributesChips color={'info'} attributes={history.attributesExpanded?.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.attribute)} />
      </Box>
      {editable === true &&
          <Box sx={{width: '100px', justifyContent: "right", textAlign: 'right'}}>
            <Button data-test={`edit-item-${index}`} onClick={onEdit}>Edit</Button>
          </Box>
      }
    </Box>
    <Box sx={{position: 'relative'}}>
      <Box className={(collapsed && !editable)?'multi_ellipsis': ''} sx={{pt: 0, whiteSpace: 'pre-line', lineHeight: 1.5}} dangerouslySetInnerHTML={{__html: history.description || ''}} data-test={`eh-description-${index}`}/>
      {collapsed && !editable &&
            <Box sx={{textAlign: 'right', right: 0, bottom: 0, position: 'absolute', background: '#fff'}}>
              <Button onClick={() => setCollapsed(false)} size="small" variant="text" sx={{textAlign: 'right', padding: '0px 0px 0px 8px'}} data-test={`see-more-${history.id}`}>... see more</Button>
            </Box>

      }
    </Box>

  </div>
}
