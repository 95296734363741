import React from "react";
import {Box, Button, Popover, Typography} from "@mui/material";

interface Props {
    onConfirm: () => void
}

export function EmploymentHistoryDeleteButton({onConfirm}: Props) {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {setOpen(false);}
    const handleConfirm = () => {
        setOpen(false);
        onConfirm();
    }
    const id = 'delete-confirmation';
    return <>
        <Popover
            id='delete-confirmation'
            open={open}
            // anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box sx={{p: 2}}>
                <Typography>Are you sure you want to delete the employment history?</Typography>
                <Box sx={{display: 'flex', mt: 2}}>
                    <Box sx={{width: 1}}><Button aria-describedby={id}  onClick={()=> setOpen(false)}>Cancel</Button></Box>
                    <Box sx={{width: 1, textAlign: 'right'}}><Button aria-describedby={id} variant="contained" color="error" onClick={handleConfirm} data-test="confirm-deletion" >Confirm</Button></Box>
                </Box>
            </Box>

        </Popover>
        <Button aria-describedby={id} variant="contained" color="error" onClick={()=> setOpen(true)} data-test="delete-employment-history">Delete</Button>
    </>
}
