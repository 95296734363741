import {Box, Button, createStyles, Typography} from "@mui/material";
import React, {useMemo} from "react";
import {Editable, useIsMobile} from "../../../../../shared";

interface Props extends Editable {
  showUpdateButton: boolean,
  onUpdateClick: () => void,
  personalSummary?: string | null,
}

export default function PersonalSummaryView({showUpdateButton, editable, onEdit, personalSummary, onUpdateClick}: Props) {
  return <Box>
    <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-start',}}>
      <Typography sx={{fontWeight: 700, fontSize: 24}}>Professional statement</Typography>
      <Box sx={{display: 'flex', gap: 3}}>
        {showUpdateButton && <Button size={'small'} variant={'outlined'} onClick={onUpdateClick} data-test={"update-from-cv-button-summary"}>Update from my Resume </Button>}
        {editable && <Button size={'small'} variant={'outlined'} onClick={onEdit} data-test="personal-summary-edit-button">Edit</Button>}
      </Box>
    </Box>
    <Box sx={{lineHeight: 1.5, pt: 1,}} dangerouslySetInnerHTML={{__html: personalSummary || ''}} data-test="summary-text-view" />
  </Box>
}
