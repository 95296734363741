import {RouteComponentProps} from "react-router";
import React from "react";
import {Box, Typography} from "@mui/material";

export default function (props: RouteComponentProps<any>) {
  return <Box >
    <Box sx={{display: 'flex', gap: 5}}>
      {/*<Box flex={1}>
        <Stepper {...props}/>
      </Box>
      <Divider orientation={'vertical'} />*/}
      <Box flex={4} sx={{mb: 10}}>
        <Typography variant={'h2'} sx={{mb: 3}}>Company created!</Typography>
        <Typography sx={{mb: 5}}>
          Thank you for you time.
        </Typography>
      </Box>
    </Box>
  </Box>
}
