import React, {PropsWithChildren} from "react";
import {useHistory} from "react-router-dom";
import {RescheduleMeetingClickHandlerContext} from "../../entity";

export const RescheduleMeetingClickHandlerContextProvider = (props: PropsWithChildren<any>) => {
  const  history = useHistory()
  return <RescheduleMeetingClickHandlerContext.Provider value={{
    handler: (positionMatchId: string, meetingId: string) => {
      history.push(`/reschedule/${positionMatchId}/meeting/${meetingId}`)
    }
  }}>
    {props.children}
  </RescheduleMeetingClickHandlerContext.Provider>
}