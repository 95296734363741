import {ContractorProfileOutput, useSaveVideoSettingsMutation} from "../../../../api/sdl";
import React from "react";
import {Box, Button, FormControl, FormLabel, Input} from "@mui/material";
import {ContractorProfile} from "../../../../entity/contractor";

export default function MyInterview({profile, onSave, editable}: {profile?: ContractorProfile, onSave: () => void, editable?: boolean}) {
  const [url, setUrl] = React.useState('');
  const [saveVideoSettings] = useSaveVideoSettingsMutation();

  React.useEffect(() => {
    if (profile?.externalVideosUrl) {
      setUrl(profile.externalVideosUrl)
    }
  }, [profile])

  const save = () => {
    saveVideoSettings({
      variables: {
        contractorProfileId: profile?.contractorId,
        payload: {
          externalVideosUrl: url,
        }
      }
    }).then(onSave)
  }

  return <div>
    {editable && <Box sx={{display:'flex', gap: 3, mb: 3, alignItems: 'flex-end'}}>
      <FormControl>
        <FormLabel>MyInterview url</FormLabel>
        <Input placeholder="Paste url here"
               name="title"
               sx={{minWidth: 600}}
               value={url}
               onChange={e => setUrl(e.target.value as string)}
        />
      </FormControl>
      <Button onClick={save}>Save</Button>
    </Box>}
    {url && <iframe src={url} frameBorder={0} width="800" height="900"/>}
  </div>
}
