import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  Button, Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel, MenuItem,
  Select, TextField,
  Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as yup from 'yup';
import React, {useContext} from "react";
import {SignIn, SignOut} from 'phosphor-react';
import {NumberOfEmployees, useCreateCompanyMutation} from "../../api/sdl";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {KeycloakContext} from "../../keycloak";

const schemaFields: any = {
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  companyName: yup
    .string()
    .required('Company name is required'),
  numberOfEmployees: yup.string().required('This is required'),
  companyUrl: yup.string(),
  password: yup.string()
    .required("Password is required"),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required("Confirmation is required"),

}

export default function RegisterCompany() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [create, {loading}] = useCreateCompanyMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      sessionStorage.setItem('token', JSON.stringify(data.createCompany.token));
      document.location.href = '/company/'
    }
  });
  const {keycloak} = useContext(KeycloakContext);

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      history.push('/positions')
    }
  }, [keycloak?.authenticated])

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      companyName: '',
      numberOfEmployees: '',
      companyUrl: '',
      roleAtCompany: '',
      companyIndustry: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        const {passwordConfirm, roleAtCompany, companyIndustry, ...fields} = values;
        create({
          variables: {
            payload: {...fields, numberOfEmployees: fields.numberOfEmployees as NumberOfEmployees}
          }
        })
      }
    },
    validateOnChange: true
  });

  return <Container maxWidth={'lg'} sx={{mb: 10}}>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, width: 1, mt: {xs: 3, md: 0}, height: {md: 80}, mb: 3, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box component={'img'} src={'/img/MDliaison_logo.png'} width={200} height={44} />
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        {!keycloak?.authenticated && <>
          <Button variant={'outlined'} data-test='contractor-signup' color={'primary'}
                  onClick={() => document.location.href = document.location.origin + '/contractor'}
          >Sign up as contractor</Button>
          <Button variant={'outlined'} color={'primary'}
                  data-test="login"
                  onClick={() => document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})}
                  startIcon={<SignIn size={24} color="#37c9ef" weight="regular" />}>Login</Button>
        </>}
        {!!keycloak?.authenticated && <Button variant={'outlined'} color={'primary'}
                                              onClick={() => {
                                                keycloak.logout()
                                              }}
                                              startIcon={<SignOut size={24} color="#37c9ef" weight="regular" />}>Logout</Button>}
      </Box>
    </Box>
    <Box >
      <Typography variant={'h1'} sx={{textAlign: 'center', mb: 3}}>Hire Experienced<br />Healthcare Sales Reps on Demand</Typography>
      <Typography sx={{textAlign: 'center', mb: 10, color: '#2c92d5'}}>MDliaison is an exclusive network of independent medical sales talent. We connect organizations with top healthcare experts.</Typography>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 5}}>
        <Box component={'form'} flex={3} onSubmit={formik.handleSubmit}>
          <Typography variant={'h3'} sx={{mb: 3}}>Contact information</Typography>
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 3, mb: 3}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
              <Box sx={{display: 'flex', gap: 3, flexDirection: {xs: 'column', md: 'row'}}}>
                <FormControl sx={{flex: 1}}>
                  <FormLabel>First Name</FormLabel>
                  <TextField placeholder="First name"
                        name="firstName"
                        data-test="first-name"
                        autoFocus={true}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  />
                  {(formik.touched.firstName && formik.errors.firstName) && <FormHelperText data-test='first-name-error' sx={{color: '#D3232F'}}>{formik.errors.firstName}</FormHelperText>}
                </FormControl>
                <FormControl sx={{flex: 1}}>
                  <FormLabel>Last Name</FormLabel>
                  <TextField placeholder="Last name"
                        name="lastName"
                        data-test='last-name'
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  />
                  {(formik.touched.lastName && formik.errors.lastName) && <FormHelperText data-test='last-name-error' sx={{color: '#D3232F'}}>{formik.errors.lastName}</FormHelperText>}
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{width:{xs: 1, md: 0.48}}}>
                  <FormLabel>Email*</FormLabel>
                  <TextField placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  {(formik.touched.email && formik.errors.email) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.email}</FormHelperText>}
                </FormControl>
              </Box>
              <Box sx={{display: 'flex', gap: 3, flexDirection: {xs: 'column', md: 'row'}}}>
                <FormControl sx={{flex: 1}}>
                  <FormLabel>Password</FormLabel>
                  <TextField placeholder="Password"
                        name="password"
                        data-test='password'
                        type={'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                  />
                  {(formik.touched.password && formik.errors.password) && <FormHelperText data-test='password-error' sx={{color: '#D3232F'}}>{formik.errors.password}</FormHelperText>}
                </FormControl>
                <FormControl sx={{flex: 1}}>
                  <FormLabel>Repeat Password</FormLabel>
                  <TextField placeholder="Repeat Password"
                        name="passwordConfirm"
                        type={'password'}
                        data-test='repeat-password'
                        value={formik.values.passwordConfirm}
                        onChange={formik.handleChange}
                        error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                  />
                  {(formik.touched.passwordConfirm && formik.errors.passwordConfirm) && <FormHelperText data-test='repeat-password-error' sx={{color: '#D3232F'}}>{formik.errors.passwordConfirm}</FormHelperText>}
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Typography variant={'h3'} sx={{mb: 3, mt: {xs: 3, md: 0}}}>Company overview</Typography>
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 3}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
              <FormControl>
                <FormLabel>Company Name*</FormLabel>
                <TextField placeholder="Company name"
                       name="companyName"
                       value={formik.values.companyName}
                       onChange={formik.handleChange}
                       error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                />
                {(formik.touched.companyName && formik.errors.companyName) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.companyName}</FormHelperText>}
              </FormControl>
              <FormControl>
                <FormLabel>Company Website</FormLabel>
                <TextField placeholder="Company Website"
                       name="companyUrl"
                       value={formik.values.companyUrl}
                       onChange={formik.handleChange}
                       error={formik.touched.companyUrl && Boolean(formik.errors.companyUrl)}
                />
                {(formik.touched.companyUrl && formik.errors.companyUrl) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.companyUrl}</FormHelperText>}
              </FormControl>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
              <FormControl sx={{width: 1}} error={formik.touched.numberOfEmployees && !!formik.errors.numberOfEmployees}>
                <FormLabel>Number of Employees*</FormLabel>
                <Select value={formik.values.numberOfEmployees} onChange={(e) => formik.setFieldValue('numberOfEmployees', e.target.value)}>
                  <MenuItem value={NumberOfEmployees.From_1To_10}>1-10</MenuItem>
                  <MenuItem value={NumberOfEmployees.From_10To_50}>10-50</MenuItem>
                  <MenuItem value={NumberOfEmployees.From_50To_250}>50-250</MenuItem>
                  <MenuItem value={NumberOfEmployees.From_250To_1000}>250-1,000</MenuItem>
                  <MenuItem value={NumberOfEmployees.From_1000To_10000}>1,000-10,000</MenuItem>
                  <MenuItem value={NumberOfEmployees.MoreThan_10000}>10,000+</MenuItem>
                </Select>
                {(formik.touched.numberOfEmployees && formik.errors.numberOfEmployees) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.numberOfEmployees}</FormHelperText>}
              </FormControl>
            </Box>
          </Box>
          <Typography  sx={{mt: 4}}>You acknowledge that the MD Liaison screening process is confidential and that you will not publicly disclose details about this process. By submitting, you acknowledge that you have read and agreed to our Terms and Conditions, Privacy Policy, and Cookie Policy.</Typography>

          <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
            <LoadingButton loading={loading} type="submit" variant={'contained'}>Create Company Profile</LoadingButton>
          </Box>
        </Box>
        <Divider orientation={'vertical'} />
        <Box flex={1}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Typography variant={'body1'}><b>More than just a marketplace</b></Typography>
            <Typography sx={{display: 'list-item'}}>We manage all onboarding and tax paperwork</Typography>
            <Typography sx={{display: 'list-item'}}>Track rep activity, time management, and payments seamlessly on our platform</Typography>
            <Typography sx={{display: 'list-item'}}>Work with a dedicated account manager to help manage matching process and contract</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Container>
}

enum CompanyIndustries {
  PhysicianPractice = 'Physician Practice',
  DigitalHealth = 'Digital Health',
  HealthTechnology = 'Health Technology',
  Hospital = 'Hospital',
  Other = 'Other',
}
