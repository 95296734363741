import {DateTime} from "luxon";
import {AttributeCategory, EmploymentHistory} from "../../../api/sdl";
import {FormFields} from "./types";
import {scrollToElement} from "../../../shared";
import {FormikErrors} from "formik";

export * from './form-schema'
export * from './types'

export function getMonthName(monthNumber: number, type: any = 'long'): string {
  const date = new Date();
  date.setMonth(monthNumber);

  return date.toLocaleString('en-US', { month: type });
}

export const formatEmploymentHistoryPeriod = (startYear?: number, startMonth?: number, endYear?: number | null, endMonth?: number | null, currentlyIsWorking?: boolean): string => {
  const startDate = DateTime.fromJSDate(new Date(startYear!, startMonth!));
  let endDate = DateTime.fromJSDate(currentlyIsWorking ? new Date() : new Date(endYear!, endMonth!));
  const diff = endDate.setLocale('en').diff(startDate.setLocale('en'), ['years', 'months']);
  let currentlyMonth = currentlyIsWorking ? Math.floor(diff.months + 1) : Math.floor(diff.months);
  let currentlyYear = Math.floor(diff.years) ;
  const Space = yearString(currentlyYear) !== '' && monthString(currentlyMonth) !== ''? `, `:'';
  const fullDate =  `${yearString(currentlyYear)}${Space}${monthString(currentlyMonth)}`;
  return fullDate ;
}

function monthString(currentlyMonth: number) {
  let monthString = '';
  if(currentlyMonth === 1) {
    monthString = `1 month`
  } else if (currentlyMonth > 1) {
    monthString = `${currentlyMonth} months`
  } else monthString = `less than a month`
  return monthString;
}
function yearString(currentlyYear: number) {
  let yearString = '';
  if(currentlyYear === 1) {
    yearString = `1 year`
  } else if (currentlyYear > 1) {
    yearString = `${currentlyYear} years`
  }
  return yearString;
}

export const toFormFields = (history: EmploymentHistory): FormFields => ({
  companyName: history.companyName,
  currentlyIsWorking: history.currentlyIsWorking,
  description: history.description,
  endDateMonth: history.endDateMonth?.toString() || '',
  endDateYear: history.endDateYear?.toString() || '',
  id: history.id || undefined,
  location: history.location || '',
  startDateMonth: history.startDateMonth.toString(),
  startDateYear: history.startDateYear.toString(),
  title: history.title,
  specialities: history.attributesExpanded?.filter(a => a.category === AttributeCategory.CallPointSpeciality).map(a => a.id) || [] as string[],
  employmentTypes: history.attributesExpanded?.filter(a => a.category === AttributeCategory.EmploymentType).map(a => a.id) || [] as string[],
})

export const toEmploymentHistory = (values: FormFields): EmploymentHistory => {
  const { specialities, employmentTypes, ...rest} = values;
  const payload: EmploymentHistory = {
    ...rest,
    attributes: [...specialities, ...employmentTypes],
    startDateMonth: parseInt(rest.startDateMonth),
    startDateYear: parseInt(rest.startDateYear),
    endDateMonth: parseInt(rest.endDateMonth),
    endDateYear: parseInt(rest.endDateYear),
  };
  if (!payload.endDateMonth) {
    delete payload.endDateMonth
  }
  if (!payload.endDateYear) {
    delete payload.endDateYear
  }
  delete payload.attributesExpanded

  return payload;
}

export function scrollToFirstError(errors?: FormikErrors<FormFields>) {
  if (errors) {
    scrollToElement(`[name="${Object.keys(errors)[0]}"]`)
  }
}

export const sortByDate = (a: EmploymentHistory, b: EmploymentHistory) => (
  new Date(b.startDateYear, b.startDateMonth, 1).valueOf() - new Date(a.startDateYear, a.startDateMonth, 1).valueOf()
)
