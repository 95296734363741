import {RouteComponentProps} from "react-router";
import React, {useMemo} from "react";
import {ProfileContext} from "../../shared/ProfileContext";
import {withLayout} from "../../shared/components/Layout";
import menu from "../components/menu";
import ContractorPositionWidget from "../../widget/contractor-position/ContractorPositionWidget";
import {PositionMatchContextProvider} from "../../entity/position-match/PositionMatchContext";


const ContractorPositionDetails = (props: RouteComponentProps<{positionId: string}>) => {

  const { positionId } = props.match.params;
  const [profile] = React.useContext(ProfileContext);

  const contractorId = useMemo(() => {
    return profile?.contractorProfile?.contractorId
  }, [profile]);

  return <PositionMatchContextProvider positionId={positionId} contractorId={contractorId} >
    <ContractorPositionWidget/>
  </PositionMatchContextProvider>
}

export default withLayout(ContractorPositionDetails, menu);
