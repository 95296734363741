import React from 'react';
import {Box, Divider, Drawer, List, ListItem, ListItemText, Toolbar, Typography} from "@mui/material";
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {MeetingListStory} from "./MeetingList.story";
import ListItemButton from "@mui/material/ListItemButton";

const drawerWidth = 240;

export function StoryBook() {
  const history = useHistory();

  return <Box sx={{ display: 'flex' }}>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Typography variant={'h3'} sx={{p: 2}}>StoryBook</Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => history.push('/meeting-list')}>
            <ListItemText primary={'MeetingList'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>

    <Box sx={{p: 3}}>
      <Switch>
        <Route path={'/meeting-list'} component={MeetingListStory} />
        <Route>
          <Redirect to={'/meeting-list'} />
        </Route>
      </Switch>
    </Box>

  </Box>
}