import {Box, Popover, Typography} from "@mui/material";
import MeetingPopup from "./MeetingPopup";
import React from "react";

interface Props {
  timeStr?: string,
  positionId?: string,
  positionMatchId?: string,
  meetingId?: string,
  positionTitle?: string,
  contractorName?: string,
}

export const MeetingSlot = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <Box sx={{position: 'relative'}} onClick={handleClick}>
      <Box sx={{position: 'absolute', right: 12, top: 4}}>
        <img src={'/img/teenyicons/info.svg'} alt=""/>
      </Box>
      <Typography data-test='booked' sx={{fontSize: 12, fontWeight: 500, lineHeight: 1.7, textAlign: 'left', pl: 1.5}}>
        {props.timeStr}<br/>Interview Booked
      </Typography>
    </Box>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MeetingPopup positionMatchId={props.positionMatchId!}
                    meetingId={props.meetingId}
                    contractorName={props.contractorName}
                    positionTitle={props.positionTitle}
                    onCloseClick={handleClose} />
    </Popover>
  </>
}
