import React from "react";
import {Box, Typography} from "@mui/material";
import {useHistory} from "react-router";

const Breadcrumbs = ({crumbs}: {crumbs: Breadcrumb[]}) => {
  const history = useHistory();
  return <Box sx={{display: 'flex', gap: .5}}>
    {crumbs.map((c, i) => <React.Fragment key={i}>
      <Typography  sx={{
        textDecoration: c.link ? 'underline' : undefined,
        fontWeight: 600,
        color: c.link ? '#3075B8' : '#929292',
        cursor: c.link ? 'pointer' : undefined,
      }}
                onClick={() => c.link && history.push(c.link)} key={c.title + i} >{c.title}</Typography>
      {i < crumbs.length - 1 && <Typography sx={{color: '#929292'}} > {"/"} </Typography>}
    </React.Fragment>)}
  </Box>
}

export default Breadcrumbs;

interface Breadcrumb {
  title: string,
  link?: string
}
