import {RouteComponentProps, useHistory} from "react-router";
import {Box, Button, Chip, CircularProgress, Divider, Typography} from "@mui/material";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import React from "react";
import {
  PositionStatus,
  useArchivePositionMutation, useCompanyByIdLazyQuery, usePositionByIdLazyQuery,
  usePublishPositionMutation, useUnPublishPositionMutation
} from "../../../api/sdl";
import PositionView from "../../../widget/position-view/PositionView";
import PositionForm from "../../../widget/position-form/PositionForm";
import CandidatesList from "../../components/candidates-list/CandidatesList";
import {useConfirm} from "../../../shared/ConfirmDialogContext";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {CommissionEditButton} from "../../../feature/position-commission-modal";

const Position = (props: RouteComponentProps<{id: string, positionId?: string, contractorId?: string}>) => {
  const {id, positionId, contractorId} = props.match.params;
  const history = useHistory();
  const [getPosition, {data}] = usePositionByIdLazyQuery({fetchPolicy: 'no-cache'})

  const [getCompany, {data: companyData}] = useCompanyByIdLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {id}
  })

  const [showForm, setShowForm] = React.useState(!positionId);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();


  const [archive] = useArchivePositionMutation({
    variables: {positionId},
    onCompleted: () => history.push('/company/' + id),
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
  })

  const [publish, {loading: publishLoading}] = usePublishPositionMutation({onCompleted: () => getPosition({variables: {id: positionId}})});
  const [unpublish, {loading: unpublishLoading}] = useUnPublishPositionMutation({onCompleted: () => getPosition({variables: {id: positionId}})})

  React.useEffect(() => {
    if (positionId) {
      getPosition({variables: {id: positionId}})
    } else {
      getCompany({variables: {id}})
    }
  }, [positionId, id])

  const position = React.useMemo(() => {
    return data?.positionById
  }, [data?.positionById])

  const company = React.useMemo(() => {
    return position?.company ? position.company : companyData?.companyById.companyProfile;
  }, [companyData, position])

  const onArchiveClick = () => confirm({
    content: 'Are sure you want to archive this position?',
    okButtonTitle: 'Yes - I want to archive this position',
    cancelButtonTitle: 'No - return to position'
  }).then(val => val && archive());

  const isActive = React.useMemo(() => {
    return position && position.status !== PositionStatus.Archived && position.status !== PositionStatus.Closed
  }, [position])

  if (positionId && !position) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh'}}>
      <CircularProgress size="15" />
    </Box>
  }

  return <div>
    {position && <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>{position?.company.name}: {position?.title}</Typography>
      <CommissionEditButton positionId={position.id!} commission={position.platformCommission} />

    </Box>}
     <Box ml={2} mt={1}>
      <Breadcrumbs crumbs={[{title: 'All companies', link: '/companies'}, {title: company?.name + ' positions', link: '/company/' + company?.id}, {title: positionId ? `${position?.title}` : 'Create new'}]} />
    </Box>
    <Box sx={{mt: 2, mb: 3, px: 2}}>
      {position && !showForm && <PositionView extraAction={<Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
        <Chip size={'small'} variant={'outlined'} sx={{borderColor: '#3075b8', color: '#3075b8'}} label={position?.status} />
        {(position?.status === PositionStatus.Draft || position?.status === PositionStatus.Archived ) && <LoadingButton variant={'contained'} onClick={() => publish({variables: {positionId}})} loading={publishLoading}>Publish</LoadingButton>}
        {position?.status === PositionStatus.Open && <LoadingButton variant={'contained'} onClick={() => unpublish({variables: {positionId}})} loading={unpublishLoading}>Unpublish</LoadingButton>}
        {isActive && <Button variant={'contained'} color={'error'} onClick={onArchiveClick}>Archive</Button>}
      </Box>}
                                  onEditClick={isActive ? () => setShowForm(true) : undefined}
                                  position={position} />}
      {showForm && <Box py={3}>
        <PositionForm id={position?.id} companyId={company?.id} onBackClick={() => setShowForm(false)}
                      companyName={company?.name}
                      onSubmit={value => {
                        setShowForm(false);
                        if (positionId) {
                          getPosition({variables: {id: positionId}})
                        } else {
                          window.scrollTo(0, 0)
                          history.replace(`/company/${props.match.params.id}/position/` + value)
                        }
                      }} />
      </Box>}
    </Box>

    {positionId && isActive && <>
      <Divider sx={{my: 3}} />
      <CandidatesList positionId={positionId} candidateId={contractorId} companyId={id} />
    </>}
  </div>
}

export default Position;
