import {IconButton} from "@mui/material";
import React from "react";

interface Props{
    onClose: () => void
}

export function CrossIconButton(props: Props) {
    return <IconButton
        onClick={props.onClose}
    >
        <img src={'/img/teenyicons/x.svg'} width={25} height={25} />
    </IconButton>
}