import {Box, Button, Container, Typography} from "@mui/material";
import React from "react";
import {RouteComponentProps} from "react-router";
import {
  usePositionMatchQuery,
} from "../../../api/sdl";
import {scheduleMeetingLink} from "../../../entity/meeting/lib/utils";
import {ScheduleMeeting} from "../../../widget";
import {ArrowLeft} from "phosphor-react";
import {
  RescheduleMeetingClickHandlerContextProvider
} from "../../../company/components/RescheduleMeetingClickHandlerContextProvider";

export default function Reschedule(props: RouteComponentProps<{ positionMatchId: string, meetingId: string }>) {
  const {positionMatchId, meetingId} = props.match.params

  const {data: positionMatchData} = usePositionMatchQuery({
    variables: {positionMatchId},
    fetchPolicy: 'no-cache'
  })

  const currentMeeting = positionMatchData?.positionMatch.meetings.find(meeting => meeting.id === meetingId);

  const handleScheduleAgainClick = () => {
    props.history.push(scheduleMeetingLink({positionId: positionMatchData!.positionMatch!.positionId, positionMatchId: positionMatchData!.positionMatch.id}))
  }

  const header = <Box sx={{my: 2}}>
    <Typography sx={{fontWeight: 700, fontSize: 18, my: 2}}>Reschedule interview</Typography>
    <Typography>Use this calendar to schedule an interview. Once you pick a time, you will be sent a calendar invite
      via email.</Typography>
  </Box>;

  return <RescheduleMeetingClickHandlerContextProvider>
    <Container sx={{my: 4}} maxWidth={'lg'}>
      <Box>
        <Button variant={'text'} startIcon={<ArrowLeft size={20} color="#3075B8" weight="regular"/>}
                onClick={() => {props.history.goBack()}}>Back</Button>
      </Box>
      <ScheduleMeeting positionMatchId={positionMatchId}
                       meeting={currentMeeting}
                       meetingListTitle={'Other interviews with this candidate'}
                       onScheduleClick={handleScheduleAgainClick}
                       onMeetingScheduled={() => {}}
                       slots={{ header }}
                       key={currentMeeting?.meetingScheduledOn}
      />
    </Container>
  </RescheduleMeetingClickHandlerContextProvider>
}

