import { DateTime } from "luxon";
import {CalendarSlotsForPositionMatchQuery, CalendarSlotStatus} from "../../../api/sdl";

export function filterClickableDatesOnly(
  slots: CalendarSlotsForPositionMatchQuery["calendarSlotsForPositionMatch"] | undefined,
  clickedDate: DateTime,
  parentOnClick: (date: DateTime) => void,)  {

    const result = slots?.find((slot) => {
      return clickedDate.valueOf() - DateTime.fromISO(slot.date).valueOf() === 0
    });

    if(result && result.status !== CalendarSlotStatus.Meeting) {
      parentOnClick(clickedDate)
    }
}