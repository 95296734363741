import {
  Box,
  Card, IconButton,
  Typography
} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import { useHistory } from "react-router-dom";
import usePageSize from "./hooks/usePageSize";
import usePage from "./hooks/usePage";
import {
  useContractorSearchTableQuery
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../components/DataGrid";
import useFilter from "./hooks/useFilter";
import ContractorFilter from "../../components/ContractorFilter";
import {JointProfileOutput} from "../../../api/sdl";
import {DateTime} from "luxon";
import {PlusCircle} from "phosphor-react";

const columns: DataGrigColumn<Partial<JointProfileOutput>>[]  = [{
  title: 'Created at',
  value: o => DateTime.fromISO(o.contractorProfile?.createdAt).toFormat('LL/dd/yyyy hh:mm a')
}, {
  title: 'Name',
  value: o => o.profile?.firstName + ' ' + o.profile?.lastName
}, {
  title: 'Email',
  value: o => o.profile?.email || '',
}, {
  title: 'Status',
  value: o => o.contractorProfile?.status || '',
}, {
  title: 'Locations',
  value: o =>
    o.contractorProfile?.cities.map((it) => `${it.city}, ${it.stateShort}`).join("; ") || ''
  ,
}, {
    title: 'CV',
    value: o => o.contractorProfile?.hasParsedCv ? 'Y' :'',
  },]

export default function ContractorList() {
  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [filter, setFilter] = useFilter();
  const [page, setPage] = usePage();
  const history = useHistory();
  const {loading, data, error} = useContractorSearchTableQuery({
    fetchPolicy: 'no-cache',
    variables: {
      payload: filter,
      page: {
        size: rowsPerPage,
        page
      }
    },
  });

  useEffect(() => {
    if (data?.contractorSearch.totalPages && data.contractorSearch.totalPages - 1 < page) {
      setPage(data?.contractorSearch.totalPages - 1)
    }
  }, [data?.contractorSearch.totalPages, page]);

  const total = useMemo(() => {
    return data?.contractorSearch.totalElements || 0;
  }, [data?.contractorSearch.totalElements]);

  const rows: Partial<JointProfileOutput>[] = React.useMemo(() => {
    return data?.contractorSearch.data as Partial<JointProfileOutput>[] || [];
  }, [data?.contractorSearch.data])

  return <div>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Box sx={{display: 'flex'}}>
        <Typography variant={'h3'} sx={{ml: 2}}>Contractors</Typography>
        <IconButton sx={{borderRadius: '50%'}} size={'small'} onClick={() => history.push('/contractor')}>
          <PlusCircle size={24} color={"#000000"} />
        </IconButton>
      </Box>
      <ContractorFilter filter={filter} onChange={setFilter} />
    </Box>
    <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                total={total}
                error={error?.message}
                onRowClick={row => history.push('/contractor/' + row.contractorProfile?.contractorId)}
                onNextClick={() => setPage(p => p + 1)}
                onPrevClick={() => setPage(p => p - 1)}
                loading={loading}
                isLastPage={data?.contractorSearch?.isLast}
      />
    </Card>
  </div>
}
