import {PositionOutput} from "../../api/sdl";
import {LayoutType} from "../../shared/types";
import {useHistory} from "react-router-dom";
import {Box,
  Typography} from "@mui/material";
import Loading from "../../shared/components/Loading";
import React from "react";
import {PositionItem} from "../../entity/position";

const ContractorPositionList = ({positions, allPositions, loading, searchLoading, view}: {positions: PositionOutput[], allPositions: PositionOutput[], loading: boolean, searchLoading: boolean, view: LayoutType}) => {
  const history = useHistory();

  return <>
    <Typography sx={{mb: 3, display:{xs:'none', md:'flex'}}}><b>Company matches {!!positions.length && `(${positions.length})`}</b></Typography>
    <Loading in={loading}>
      <Box data-test='matches' sx={{display: 'grid', gridTemplateColumns: {xs: `repeat(${view === 'grid' ? '1' : '1'}, 1fr)`, md: `repeat(${view === 'grid' ? '2' : '1'}, 1fr)`}, width: '100%', gap: 2}}>
        {!!positions.length && positions.map((item) => <React.Fragment key={item.id}>
          <PositionItem view={view} position={item} onViewDetailsClick={() => history.push('/positions/' + item.id)} />
          {/*<PositionItem view={view} position={toPositionOutput(item)} confirmed={item.status === PositionMatchStatus.ContractorConfirmed} onViewDetailsClick={() => history.push('/position/' + item.id)} />*/}
        </React.Fragment>)}
      </Box>
      {(!loading && !positions.length) && <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 200}}>
          <Typography>No positions</Typography>
      </Box>}
    </Loading>
    <Typography sx={{mb: 3, mt: 6}}><b>Open projects {!!allPositions.length && `(${allPositions.length})`}</b></Typography>
    <Loading in={searchLoading}>
      {(!searchLoading && !allPositions.length) && <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 200}}>
          <Typography>No positions</Typography>
      </Box>}
      <Box data-test='projects' sx={{display: 'grid', gridTemplateColumns: {xs: `repeat(${view === 'grid' ? '1' : '1'}, 1fr)`, md: `repeat(${view === 'grid' ? '2' : '1'}, 1fr)`}, width: '100%', gap: 3}}>
        {!!allPositions.length && allPositions.map((item) => <React.Fragment key={item.id}>
          <PositionItem view={view} position={item} onViewDetailsClick={() => history.push('/positions/' + item.id)} />
        </React.Fragment>)}
      </Box>
    </Loading>
  </>;
}

export default ContractorPositionList;