import {Notification, NotificationType, ToDoStatus} from "../../../api/sdl";
import {Button} from "@mui/material";
import React from "react";
import {useHistory} from "react-router-dom";

function property(item: Notification, key: string) {
  return item.properties?.find(p => p.key === key)?.value
}

export function NotificationButton({item}: { item: Notification }) {
  const history = useHistory();

  function contractorPositionLink() {
    const positionId = property(item, "positionId")
    if (!positionId)
      return `/positions/`
    else
      return `/position/${property(item, "positionId")}`
  }
  return <>
    {item.toDoStatus !== ToDoStatus.Completed && <>
      {item.type === NotificationType.ContractorVideoInterviewRequest &&
          <Button variant={'contained'} onClick={() => history.push('/video')}>Record video answers</Button>}
      {item.type === NotificationType.ContractorAddedToPosition &&
          <Button variant={'contained'} onClick={() => history.push(contractorPositionLink() )}>Review position</Button>}
      {item.type === NotificationType.ContractorConfirmedPositionAvailability &&
          <Button variant={'contained'}
                  onClick={() => history.push(
                    `/company/${property(item, "companyId")}/position/${property(item, "positionId")}/${property(item, "contractorId")}`
                  )}>Review position</Button>}
    </>}
  </>
}
