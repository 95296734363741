import {RouteComponentProps} from "react-router-dom";
import {
  Box, Typography,
  Container, FormLabel, TextField, FormControl,
} from "@mui/material";
import React, {useMemo, useState} from "react";
import InfoCard from "../../../shared/components/InfoCard";
import * as _ from "lodash"
import LastMeetingCard from "./ui/LastMeetingCard";
import useMeetingsForPosition from "./hooks/useMeetingsForPosition";
import HireContractorButton from "./features/HireContractorButton";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import usePositionById from "../../../shared/hooks/usePositionById";
import useCandidatesByPosition from "../Candidates/hooks/useCandidatesByPosition";
import DatePicker from "react-datepicker";
import {
  fullCommissionMultiplier,
  expectedSalaryFromPositionMatch,
  platformCommissionMultiplier
} from "../../../shared/utils";
import {candidateLink} from "../../../entity/meeting/lib/utils";

function Hire(props: RouteComponentProps<{positionId: string, positionMatchId: string}>){
  const {positionId, positionMatchId} = props.match.params;
  const {position} = usePositionById(positionId);
  const {candidates} = useCandidatesByPosition(positionId);
  const [anticipatedStartDate, setAnticipatedStartDate] = useState<Date | null>(null)

  const candidate = useMemo(() => {
    return candidates.find(c => c.id === positionMatchId)
  }, [candidates]);

  const meetings = useMeetingsForPosition(positionId);

  const lastMeeting = React.useMemo(() => {
    const contractorMeetings = meetings.filter((item) => positionMatchId === item.positionMatchId)
    return _.orderBy(contractorMeetings, 'date').at(-1)
  }, [meetings])

  if(!candidate) {
    return null;
  }
  return <Box sx={{p: 3}}>
    <Container maxWidth={'lg'}>
      <Breadcrumbs crumbs={[
        {title: 'Roles', link: '/positions'},
        {title: position?.title || 'Loading...', link: '/position/' + positionId},
        {title: candidate?.displayName || 'Loading...', link: candidateLink(false, {positionId: positionId, contractorId: candidate.contractorId})},
        {title: 'Hire'}
      ]} />
      <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between'}}>
        <Typography variant={'h2'} sx={{mb: 1, mt: 3}}>Hire {candidate?.displayName}</Typography>
      </Box>
      {!!lastMeeting && <>
          <Typography variant={'body1'} sx={{mb: 1}}><b>Last meeting</b></Typography>
          <LastMeetingCard item={lastMeeting}
                           name={candidate?.displayName}
                       onCandidateClick={() => props.history.push(candidateLink(false, {positionId: positionId, contractorId: candidate.contractorId}))}
          />
      </>}
      <InfoCard
        data-test='hire-card'
        sx={{mb: '20px'}}
        title={`Please verify the following rate to hire ${candidate?.firstName}`}
        content={<>
          <Typography>Contractor's Rate: <b>{expectedSalaryFromPositionMatch(candidate)}</b></Typography>
          <Typography>MDliaison service fee: <b>{expectedSalaryFromPositionMatch(candidate , platformCommissionMultiplier(position!.platformCommission))}</b></Typography>
          <Typography>Total Hiring Rate: <b>{expectedSalaryFromPositionMatch(candidate, fullCommissionMultiplier(position!.platformCommission))}</b></Typography>
          <FormControl data-test='start-date'>
            <FormLabel>Anticipated Start Date</FormLabel>
            <DatePicker selected={anticipatedStartDate}                       
                        customInput={<TextField placeholder="Select date" />}
                        minDate={new Date()}
                        onChange={date => setAnticipatedStartDate(date)} />
          </FormControl>
        </>}
      />
      <HireContractorButton positionMatchId={positionMatchId}
                            anticipatedStartDate={anticipatedStartDate}
                            onCompleted={() => props.history.push('/hiring-complete')} />
    </Container>
  </Box>
}

export default Hire

