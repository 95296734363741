import {Card, Typography} from "@mui/material";
import React, {useContext} from "react";
import {RouteComponentProps} from "react-router";
import EditableUserCalendar from "../components/Calendar/EditableUserCalendar";
import {ProfileContext} from "../ProfileContext";

const CalendarPage = (props: RouteComponentProps<any> & {contractorId?: string, companyId?: string}) => {
  const [profile] = useContext(ProfileContext)

  return <div>
    <Card sx={{m: 3, p: 2}}>
      <Typography variant={'h3'}>My interview availability</Typography>
      <EditableUserCalendar contractorId={profile!!.contractorProfile!!.contractorId} />
    </Card>
  </div>
}

export default CalendarPage
