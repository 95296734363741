import React, {useContext} from 'react';
import {KeycloakContext} from "../../keycloak";

export function StartRedirect() {
  const {initialized, keycloak} = useContext(KeycloakContext);

  React.useEffect(() => {
    if(initialized && keycloak?.authenticated === false ) {
      keycloak.login();
    }
  }, [initialized, keycloak?.authenticated]);
  return null;
}