import {
  Alert,
  Box, Button, Card, Stack, Typography
} from "@mui/material";
import React from "react";
import {
  useStripeAccountLinkMutation,
  useStripeAccountQuery, useStripeGenerateUpdateLinkMutation,
  useStripeLoginLinkMutation
} from "../../api/sdl";
import Loading from "../../shared/components/Loading";
import InfoCard from "../../shared/components/InfoCard";
import {LoadingButton} from "@mui/lab";


function Settings() {
  const stripeAccountData = useStripeAccountQuery({fetchPolicy: 'no-cache'});
  const [getstripeAccountLink, stripeAccountLinkData] = useStripeAccountLinkMutation({
    onCompleted: res => res.stripeAccountLink && (document.location.href = res.stripeAccountLink),
  });

  const [getStripeLoginLink, stripeLoginLinkData] = useStripeLoginLinkMutation({
    onCompleted: res => res.stripeGenerateLoginLink && window.open(res.stripeGenerateLoginLink),
  });

  const stripeAccount = React.useMemo(() => {
    return stripeAccountData.data?.stripeAccount;
  }, [stripeAccountData.data])

  const showLoginButton = React.useMemo(() => {
    return !!stripeAccount?.integrationCompleted && !!stripeAccount?.payoutsEnabled && !!stripeAccount?.fastPayoutsEnabled
  }, [stripeAccount])

  const showInfo = React.useMemo(() => {
    return !!stripeAccount?.integrationCompleted && (!stripeAccount?.payoutsEnabled || !stripeAccount?.fastPayoutsEnabled)
  }, [stripeAccount])

  return <Box>
      <Card sx={{m: 3, minHeight: '80vh', p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
        <Typography variant={'h3'}>Manage Payouts</Typography>
        <Loading in={stripeAccountData.loading}>

          <InfoCard
            content={<Typography sx={{fontWeight: 600}}>MDliaison does not hold any bank account information on our platform all data is protected via Stripe.</Typography>}
          />

          <Stack sx={{width: 1, maxWidth: {sm: 300}, my: 3, ml: {md: 2}}} spacing={2}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography >Payouts in your account are enabled</Typography>
              <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.payoutsEnabled ? 'tick' : 'x'}-circle.svg`} width={16} height={16} />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography >Fast payouts are enabled</Typography>
              <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.fastPayoutsEnabled ? 'tick' : 'x'}-circle.svg`} width={16} height={16} />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography >Direct Deposit Initiated</Typography>
              <Box component={'img'} src={`/img/teenyicons/${!!stripeAccount?.integrationCompleted ? 'tick' : 'x'}-circle.svg`} width={16} height={16} />
            </Box>
          </Stack>
          <Box sx={{ml: {md: 2}}}>
            {showInfo && <Alert sx={{my: 2, fontWeight: 500, fontSize: 14}} variant="outlined" color={'info'}>Stripe is verifying your information. Please, check the status later.</Alert>}
            {!!showLoginButton && <LoadingButton loading={stripeLoginLinkData.loading} variant={'contained'} onClick={() => getStripeLoginLink()}>View my payouts</LoadingButton>}
            {!stripeAccount?.integrationCompleted && <LoadingButton onClick={() => getstripeAccountLink()} variant={'contained'} loading={stripeAccountLinkData.loading}>Add payout account</LoadingButton>}
          </Box>
        </Loading>
      </Card>
  </Box>;
}

export default Settings
