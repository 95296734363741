import {
  Box, IconButton, Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {usePositionMatchQuery} from "../../../api/sdl";
import Loading from "../../../shared/components/Loading";
import {X} from "phosphor-react";
import {MeetingCard} from "./MeetingCard";
import {MeetingControlButtons} from "./MeetingControlButtons";

interface Props {
  positionMatchId?: string,
  meetingId?: string,
  positionTitle?: string,
  contractorName?: string,
  onCloseClick: () => void,
}

function MeetingPopup(props: Props) {
  const {positionMatchId, positionTitle, contractorName, meetingId, onCloseClick} = props;
  const {data, loading} = usePositionMatchQuery({
    fetchPolicy: 'no-cache',
    variables: {positionMatchId},
    skip: !positionMatchId,
  })

  const meeting = data?.positionMatch.meetings.find(m => m.id === meetingId);

  return (
    <Box data-test='popup' sx={{borderRadius: '10px', p: '16px 18px'}}>
      <Loading in={loading} height={35}>
        <Stack spacing={1}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography sx={{fontWeight: 500}}>{contractorName}</Typography>
            <IconButton size={'small'}
                        color={'secondary'}
                        onClick={onCloseClick}>
              <X size={16} color="#000" weight="regular" />
            </IconButton>
          </Box>
          <Typography sx={{fontWeight: 500, mb: 1}}>{positionTitle}</Typography>
          {meeting && <MeetingCard meeting={meeting}
                                   slots={{
                                     button: <MeetingControlButtons meeting={meeting}
                                                                    positionMatchId={positionMatchId}
                                                                    onButtonClick={onCloseClick}
                                                                    onCancelMeeting={() => {
                                                                      window.location.reload();
                                                                    }} />
                                   }}
          />}
        </Stack>
      </Loading>
    </Box>
  )
}
export default MeetingPopup
