import * as React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {
  Box, Button,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { FormValues } from "../lib/types";
import {CitySelector} from "../../../../../feature/city-selector";
import {CityFormValue} from "../../../../../feature/city-selector/lib/types";

const initialValues: FormValues = {
  linkedInUrl: '',
  cities: [],
}

const schemaFields: any = {
  linkedInUrl: yup
    .string(),
  cities: yup
    .array(),
}

interface Props {
  onSubmit: (values: FormValues) => void,
  onBackClick: () => void,
  loading: boolean,
  initial: FormValues,
}

export function ContactInformationForm(props: Props) {
  const {
    loading,
    onSubmit,
    onBackClick,
    initial
  } = props;
  const formik = useFormik({
    initialValues: initial || initialValues,
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      formik.isValid && onSubmit(values)
    },
    validateOnChange: true
  });


  return <Box component={'form'} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
      <FormControl>
        <FormLabel>LinkedIn profile URL</FormLabel>
        <TextField placeholder="LinkedIn URL"
               name="linkedInUrl"
               value={formik.values.linkedInUrl}
               onChange={formik.handleChange}
               error={formik.touched.linkedInUrl && Boolean(formik.errors.linkedInUrl)}
        />
        {(formik.touched.linkedInUrl && formik.errors.linkedInUrl) && <FormHelperText sx={{color: '#D3232F'}}>{formik.errors.linkedInUrl}</FormHelperText>}
      </FormControl>

      <CitySelector onChange={(x) => formik.setFieldValue('cities', x)} value={props.initial.cities as CityFormValue[]} />

      <Box sx={{mt: 2, display: 'flex', gap: 3}}>
        <Button variant={'outlined'} color={'secondary'} onClick={onBackClick}>Back</Button>
        <LoadingButton loading={loading} variant={'contained'} type="submit">Save</LoadingButton>
      </Box>

    </Box>
  </Box>
}
