import {Box, Button, Card, Chip, Divider, Typography} from "@mui/material";
import React from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  PositionOutput,
  PositionStatus,
  useCompanyByIdQuery,
  usePositionsByCompanyIdQuery,
  useUserProfileLazyQuery
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../components/DataGrid";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import * as _ from 'lodash';

const columns: DataGrigColumn<Partial<PositionOutput>>[]  = [{
  title: 'Title',
  value: o => o.title || ''
}, {
  title: 'Candidates',
  value: o => (o.numberOfCandidates || 0) + '',
}, {
  title: 'Status',
  value: o => <Chip size={'small'}
                    label={_.startCase(o.status?.toLowerCase())}
                    sx={{background: [PositionStatus.Archived, PositionStatus.Closed, PositionStatus.Deleted].includes(o.status as any) ? '#bf2121' : '#3075b8'}} />,
},
  {
    title: 'Commission',
    value: o => <>{o.platformCommission}%</>,
  }];

export default function PositionList(props: RouteComponentProps<{id: string}>) {
  const history = useHistory();
  const {data: companyData} = useCompanyByIdQuery({
    fetchPolicy: 'no-cache',
    variables: {id: props.match.params.id}
  })
  const {loading, data, error} = usePositionsByCompanyIdQuery({
    fetchPolicy: 'no-cache',
    variables: {companyId: props.match.params.id, page: {page: 0, size: 1000}}
  });

  const [getUserProfile, {data: userProfileData}] = useUserProfileLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    if (companyData?.companyById) {
      getUserProfile({variables: {userId: companyData?.companyById?.companyProfile.userId}})
    }
  }, [companyData])

  const company = React.useMemo(() => {
    return companyData?.companyById;
  }, [companyData])

  const userProfile = React.useMemo(() => {
    return userProfileData?.userProfile
  }, [userProfileData])

  const rows = React.useMemo(() => {
    return data?.positionsByCompanyId?.data || [];
  }, [data?.positionsByCompanyId])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>{company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : 'Loading...'} </Typography>
    </Box>
    <Box sx={{mx: 2, my: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Breadcrumbs crumbs={[{title: 'All companies', link: '/companies'}, {title: company?.companyProfile?.name ? `${company?.companyProfile?.name} positions` : '...'}, ]} />
      <Button size={'small'} variant={'contained'} onClick={() => history.push(`/company/${props.match.params.id}/position`)}>Create new</Button>
    </Box>
    <Divider />
    <Box component={'table'} sx={{width: 'fit-content', my: 1, ml: 2}}>
      <tbody>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600}}>Company name:</Typography>
        </td>
        <td>
          <Typography>{company?.companyProfile?.name}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600}}>Web page:</Typography>
        </td>
        <td>
          <Typography>{company?.companyProfile?.url}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600, mr: 3}}>Contact name:</Typography>
        </td>
        <td>
          <Typography>{userProfile?.firstName} {userProfile?.lastName}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600}}>Phone:</Typography>
        </td>
        <td>
          <Typography>{userProfile?.phone}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600}}>Email:</Typography>
        </td>
        <td>
          <Typography>{userProfile?.email}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600, mr: 3}}>Number of employees:</Typography>
        </td>
        <td>
          <Typography>{company?.companyProfile?.numberOfEmployees ? _.upperFirst(_.lowerCase(company?.companyProfile?.numberOfEmployees)) : ''}</Typography>
        </td>
      </tr>
      <tr>
        <td>
          <Typography sx={{fontWeight: 600, mr: 3}}>Manager:</Typography>
        </td>
        <td>
          <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
            <Typography>{[company?.accountManager?.firstName, company?.accountManager?.lastName].filter(v => !!v).join(' ') + ` (${company?.accountManager?.email})`}</Typography>
            <Button size={'small'} variant={'outlined'} onClick={() => history.push(`/company/${props.match.params.id}/manager`)}>Edit</Button>
          </Box>
        </td>
      </tr>
      </tbody>
    </Box>
    <Divider />
    <Card sx={{mt: 1, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={1000}
                page={0}
                hidePaginator
                onRowClick={row => history.push(`/company/${props.match.params.id}/position/` + row.id)}
                error={error?.message}
                onNextClick={() => {}}
                onPrevClick={() => {}}
                loading={loading}
                isLastPage={true}
      />
    </Card>
  </div>
}
