import * as React from "react";
import {JointProfileOutput, useGetMyProfileLazyQuery} from "../api/sdl";
import {useContext} from "react";
import {KeycloakContext} from "../keycloak";

export const ProfileContext = React.createContext<[JointProfileOutput | undefined, () => void, boolean, boolean]>([undefined, () => {}, false, false]);

export const ProfileContextProvider = (props: any) => {
  const [profile, setProfile] = React.useState<JointProfileOutput>();
  const { keycloak} = useContext(KeycloakContext);
  const [getProfile, {loading, called}] = useGetMyProfileLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(() => {
    refresh()
  }, [keycloak?.authenticated])

  const refresh = React.useCallback(() => {
    if (keycloak?.authenticated) {
      return getProfile().then(res => setProfile(res.data?.myProfile as JointProfileOutput))
    }
  }, [keycloak?.authenticated])

  return (
    <ProfileContext.Provider value={[profile, refresh, loading, called]}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export const withProfileContextProvider = (WrappedComponent: any) => (props: any) => {
  return <ProfileContextProvider>
    <WrappedComponent {...props}/>
  </ProfileContextProvider>
}
