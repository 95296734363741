import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  Container
} from "@mui/material";
import React, {useEffect} from "react";
import {withLayout} from "../../shared/components/Layout";
import menu from "../components/menu";
import {ContractorCard} from "../../widget";
import {ProfileContext} from "../../shared/ProfileContext";
import {useSnackbar} from "notistack";

const ProfessionalProfile = (props: RouteComponentProps<any>) => {
  const [profile, refresh] = React.useContext(ProfileContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refresh()
  }, [refresh]);

  return <Container sx={{p: {md: 3}}} maxWidth={'xl'}>
    {profile && <ContractorCard profile={profile.contractorProfile || undefined}
                                userProfile={profile.profile || undefined}
                                editable
                                onUpdate={() => {
                                  enqueueSnackbar('Profile was saved successfully', {variant: 'success'})
                                  refresh()
                                }}
                                onEdit={() => {}}
                                onVideoEditClick={() => history.push('/video')}
                                showSideStripe={false}
    />}
  </Container>;
}

export default withLayout(ProfessionalProfile, menu)
