import {Editable} from "../../../shared";
import {
    EmploymentHistory,
    EmploymentHistoryPayloadInput, useCopyEmploymentHistoryFromResumeMutation,
    useSaveEmploymentHistoryMutation
} from "../../../api/sdl";
import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {DisplayMode, EmploymentHistoryData, sortByDate} from "../lib";
import {EmploymentHistoryView} from "./EmploymentHistoryView";
import {EmploymentHistoryModal} from "./EmploymentHistoryModal";
import { enqueueSnackbar } from "notistack";

interface Props extends Editable {
    contractorId?: string;
    histories?: EmploymentHistory[];
    updatableFromCv?: boolean;
    onItemDelete: (employmentHistoryId: string) => void;
    onUpdate: () => void;
}

export function EmploymentHistoryEditableList(props: Props) {
    const {histories, editable, updatableFromCv,  onUpdate} = props;
    const [displayMode, setDisplayMode] = React.useState(DisplayMode.View);

    const [modalEHid, setModalEHid] = React.useState<string>();
    const [showEditor, setShowEditor] = React.useState<boolean>(false);
    const [saveHistory, {loading}] = useSaveEmploymentHistoryMutation();
    const [copyFromResume] = useCopyEmploymentHistoryFromResumeMutation();

    const onFormUpdate = async (value: EmploymentHistoryData) => {

        await saveHistory({variables: {payload: value as EmploymentHistoryPayloadInput, contractorProfileId: props.contractorId}});
        setModalEHid(undefined);
        setShowEditor(false);
        props.onUpdate()
    }

    const handleUpdateFromResume = async() => {
        await copyFromResume({variables: {contractorProfileId: props.contractorId}})
        enqueueSnackbar('Employment history has copied from the Resume', {variant: 'success'})
        props.onUpdate();
    }
    const onItemEditClick = (itemId: string) => {
        setModalEHid(itemId);
        setShowEditor(true);
    }

    const handleEditClick = () => {
        if(displayMode === DisplayMode.Edit) {
            setDisplayMode(DisplayMode.View)
        } else {
            setDisplayMode(DisplayMode.Edit)
        }
    }

    const handleAddClick = () => {
        setModalEHid(undefined)
        setShowEditor(true)
    }

    const handleItemDelete = () => {
        if(modalEHid) {
            props.onItemDelete(modalEHid)
        }
        setShowEditor(false)
    }

    return <>
        <Box>
            <EmploymentHistoryModal open={showEditor}
                                                  employmentHistoryId={modalEHid}
                                                  onFormSubmit={onFormUpdate}
                                                  onClose={() => setShowEditor(false)}
                                                  onDelete={() => handleItemDelete()}

            />
            <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-start', mb: 2}}>
                <Typography sx={{fontWeight: 700, fontSize: 24}}>Experience</Typography>
                <Box sx={{display: 'flex', gap: 3}}>
                    {displayMode === DisplayMode.Edit && updatableFromCv && <Button size='small' variant='outlined' onClick={() => handleUpdateFromResume()} data-test={"update-from-cv-button-experience"}>Copy from my Resume</Button>}
                    {displayMode === DisplayMode.Edit && <Button size='small' variant='outlined' onClick={handleAddClick} data-test={"add-work-experience-button"}>Add new</Button>}
                    {editable && <Button size='small' variant='outlined' onClick={handleEditClick} data-test={"edit-work-experience-button"}>
                        {displayMode === DisplayMode.View? 'Edit' : 'Preview'}
                    </Button>}
                </Box>
            </Box>

            <Stack spacing={4}>
                {histories?.sort(sortByDate).map((h, i, {length}) => <React.Fragment key={i}>
                    <EmploymentHistoryView history={h} editable={displayMode === DisplayMode.Edit} onEdit={() => { onItemEditClick(h.id)}} index={i} />
                    {/*{i < length - 1 && <Divider />}*/}
                </React.Fragment>)}
            </Stack>

            {!histories?.length && <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', width: 1}}>
                <Typography  sx={{mt: 23}} data-test="no-employment-history">No data available</Typography>
            </Box>}
        </Box>
    </>
}
