import {useHireContractorMutation} from "../../../../api/sdl";
import {Button} from "@mui/material";
import React from "react";
import {enqueueSnackbar} from "notistack";
import {DateTime} from "luxon";

export default function HireContractorButton({positionMatchId, anticipatedStartDate, onCompleted}: {positionMatchId: string, anticipatedStartDate?: Date | null, onCompleted: () => void}) {
  const [hireContractor] = useHireContractorMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('The contractor was successfully hired', {variant: 'success'});
      onCompleted();
    },
  })

  const onHireClick = () => {
    if (anticipatedStartDate) {
      hireContractor({
        variables: {
          positionMatchId,
          anticipatedStartDate: DateTime.fromJSDate(anticipatedStartDate!).toFormat('yyyy-LL-dd')
        }
      })
    } else {
      throw Error('anticipatedStartDate not provided')
    }
  }

  return <Button data-test='hire' onClick={() => onHireClick()} disabled={!anticipatedStartDate} variant={'contained'}>Hire contractor</Button>
}
