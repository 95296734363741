import {Box, Button, Card, styled, Typography} from "@mui/material";
import {DateTime} from "luxon";
import React, {useState} from "react";
import {GuestPerson} from "src/api/sdl";
import {AddGuestDialog} from "../../../feature/calendar/ui/AddGuestDialog";
import {MeetingCard} from "./MeetingCard";
import {AppointmentsIcon, RoundedIcon} from "../../../shared";

interface InterviewEditorProps {
  guests?: GuestPerson[];
  meetingDate: DateTime;
  onCancel: () => void,
  onScheduled: (guests: GuestPerson[]) => void,
}

export function MeetingSetupForm(props: InterviewEditorProps) {

  const [addGuestOpen, setAddGuestOpen] = React.useState(false);
  const [guests, setGuests] = useState<GuestPerson[]>(props.guests || [])

  const handleAddGuestDialogSave = (guest: GuestPerson) => {
    setGuests(prevState => [...prevState, guest]);
    setAddGuestOpen(false);
  }

  const handleScheduleClick = () => {
    props.onScheduled(guests);
  }

  const handleRemoveGuestClick = (guest: GuestPerson) => {
    setGuests(prev => prev.filter(g => g !== guest));
  }

  return <Wrapper data-test='metting-setup-form' >
    {addGuestOpen && (
      <AddGuestDialog open
                      onClose={() => setAddGuestOpen(false)}
                      onSave={handleAddGuestDialogSave}/>
    )}
    <Typography sx={{fontWeight: 700, fontSize: 18, marginTop: 2}} >
      Interview details
    </Typography>

    <Card sx={{py: 2, px: 3}}>
      <MeetingCard meeting={{ guests, meetingScheduledOn: props.meetingDate.toISO() as string }}
                   showGuests
                   slots={{
                     icon: <RoundedIcon iconSize={20} size={36} icon={<AppointmentsIcon />} />,
                     button: (
                       <Button sx={{alignSelf: 'self-end', mt: 2}}
                               data-test="add-guest-button"
                               onClick={() => setAddGuestOpen(true)}>Add guest</Button>
                     )
                   }}
                   onGuestRemoveClick={handleRemoveGuestClick}
      />
    </Card>

    <Box sx={{display: 'flex', flexDirection:'column', gap: 3, justifyContent: 'flex-end', width:'300px'}}>
      <Box>
        <Button sx={{color:'#6D6D6D'}} data-test="cancel-meeting"
                onClick={props.onCancel}>Discard changes</Button>
        <Button color={'primary'}
                variant={"contained"}
                data-test="schedule-meeting-button"
                onClick={handleScheduleClick}>
          Schedule meeting
        </Button>
      </Box>
      <Box>
        <Typography variant={"body2"} sx={{fontSize: '14px'}}>
          Once the meeting is scheduled, you and any guests will receive a calendar invite notification.
        </Typography>
      </Box>
    </Box>
  </Wrapper>
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})