import {Route, RouteProps, useLocation} from 'react-router-dom'
import React, {useContext} from "react";
import {Box, CircularProgress} from "@mui/material";
import {KeycloakContext, KeycloakRoles} from "../keycloak";

const PrivateRoute = ({children, ...rest}: RouteProps) => {
  const {initialized, keycloak} = useContext(KeycloakContext);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    if (initialized) {
      if (!keycloak?.authenticated) {
        document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href});
      } else {
        setLoading(false);
      }
    }
  }, [keycloak?.authenticated, initialized])


  if (loading) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh'}}>
      <CircularProgress size="15" />
    </Box>
  }

  return(
    <Route {...rest}>
      {children}
    </Route>
  )
}

export default PrivateRoute;
