import {Editable} from "../../../../shared";
import React from "react";
import PersonalSummaryView from "./ui/PersonalSummaryView";
import PersonalSummaryForm from "./ui/PersonalSummaryForm";
import useSaveProfile from "../../api/use-save-profile";
import PersonalSummaryCompare from "./ui/PersonalSummaryCompare";

enum DisplayMode {
  View,
  Edit,
  UpdateFormPdf
}

interface Props extends Editable {
  personalSummary?: string | null,
  cvPersonalSummary?: string | null,
  contractorId?: string,
  updatableFromCv: boolean,
  onUpdate?: () => void,
}

export default function PersonalSummary(props: Props) {
  const {personalSummary, cvPersonalSummary, contractorId, editable, onUpdate, updatableFromCv} = props;

  const {save, loading} = useSaveProfile(contractorId);

  const [displayMode, setDisplayMode] = React.useState(DisplayMode.View);

  const onSubmit = async (values: {personalSummary: string}) => {
    await save(values);
    setDisplayMode(DisplayMode.View);
    onUpdate && onUpdate();
  }

  return <>
    {displayMode === DisplayMode.View && (
      <PersonalSummaryView showUpdateButton={updatableFromCv}
                           personalSummary={personalSummary}
                           onEdit={() => setDisplayMode(DisplayMode.Edit)}
                           editable={editable}
                           onUpdateClick={() => setDisplayMode(DisplayMode.UpdateFormPdf)} />
    )}

    {displayMode === DisplayMode.Edit && (
      <PersonalSummaryForm onSubmit={onSubmit}
                           personalSummary={personalSummary}
                           loading={loading}
                           onBackClick={() => setDisplayMode(DisplayMode.View)} />
    )}

    {displayMode === DisplayMode.UpdateFormPdf && (
      <PersonalSummaryCompare onSubmit={onSubmit}
                              cvPersonalSummary={cvPersonalSummary}
                              profilePersonalSummary={personalSummary}
                              loading={loading}
                              onBackClick={() => setDisplayMode(DisplayMode.View)} />
    )}
  </>
}
