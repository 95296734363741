import {Autocomplete, Chip, TextField} from "@mui/material";
import * as _ from "lodash";
import {getCategoryColor, states} from "../utils";
import {XCircle} from "phosphor-react";
import React, {useMemo} from "react";
import useContractorAttributes from "../hooks/useContractorAttributes";

interface Props<T extends string | string[] | undefined> {
  value: T,
  onChange: (values: T | null) => void,
  loading?: boolean,
  multiple?: boolean,
}

function StatesFilter<T extends string | string[] | undefined>({value, onChange, loading, multiple}: Props<T>) {
  return <Autocomplete
    multiple={multiple}
    disableCloseOnSelect
    sx={{minWidth: 252, maxWidth: 252}}
    loading={loading}
    options={states.map(v => v.abbreviation)}
    getOptionLabel={(option) => states.find(s => s.abbreviation === option)?.name || option}
    limitTags={1}
    renderInput={(params) => <TextField placeholder={'Locations'} {...params} />}
    value={value}
    onChange={(event, newValue) => onChange(newValue as T)}
    renderTags={(tags, getTagProps) =>
      tags.map((item, index) => (
        <Chip
          size={'small'}
          variant="outlined"
          deleteIcon={<XCircle size={18} color="#fff" weight="fill" />}
          {...getTagProps({ index })}
          label={states.find(s => s.abbreviation === item)?.name}
        />
      ))
    }
  />
}

export default StatesFilter
