import React, {useState} from 'react';
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import Keycloak, {KeycloakInitOptions} from 'keycloak-js'
import config from "./config";
import Loading from "./shared/components/Loading";

export const keycloakConfig = {
  realm: config.realm,
  url: config.keycloakUrl,
  clientId: 'frontend'
}

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// @ts-ignore
export const keycloak: Keycloak.KeycloakInstance = new Keycloak(keycloakConfig);

export enum KeycloakRoles {
  admin = 'Admin',
  company = 'Company',
  contractor = 'Contractor'
}

export function useUserId(): string | undefined {
  return keycloak?.tokenParsed?.sub;
}

interface KeycloakContextProps {
  keycloak: Keycloak.KeycloakInstance,
  initialized: boolean
}

export const KeycloakContext = React.createContext<KeycloakContextProps>({
  keycloak,
  initialized: false,
});

export function KeycloakProvider(props: React.PropsWithChildren<any>) {
  const [initialized, setInitialized] = useState(false);

  React.useEffect(() => {
    if (keycloak) {
      if (!config.isProd && !(window as any).keycloak) {
        (window as any).keycloak = keycloak;
      }
      if (!initialized) {
        const initOptions: KeycloakInitOptions = {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri: window.location.origin,
        };

        const tokens = sessionStorage.getItem('token');

        if (tokens) {
          let {accessToken: token, refreshToken, idToken} = JSON.parse(tokens);
          Object.assign(initOptions, {token, refreshToken, idToken})
        }

        keycloak.init(initOptions).then((authenticated: boolean) => {
          setInitialized(true);
        })

        keycloak.onAuthRefreshError = () => {
          console.log('onAuthRefreshError');
          // it made infinite reload.
          // keycloak.logout();
        };

        keycloak.onTokenExpired = () => {
          keycloak.updateToken(10)
        };

      }
    }
  }, [initialized])


  if (!initialized) {
    return <Loading in />
  }

  return  <KeycloakContext.Provider value={{initialized, keycloak}}>
    {props.children}
  </KeycloakContext.Provider>
}

export const withKeycloakContext = (WrappedComponent: any) => (props: any) => {
  return (
    <KeycloakProvider >
      <WrappedComponent {...props} />
    </KeycloakProvider>
  )
}

export const withKeycloak = (WrappedComponent: any) => (props: any) => {
  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const [authtorized, setAuthtorized] = React.useState<boolean>();

  React.useEffect(() => {
    keycloak.init({onLoad: 'login-required'}).then((authenticated: boolean) => {
    //  console.log(keycloak);
      setAuthenticated(authenticated);
    })
  }, []);

  React.useEffect(() => {
    const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
    setAuthtorized(roles.includes('ROLE_ADMIN'));
  }, [keycloak?.tokenParsed?.realm_access?.roles, authenticated])

  let component = null;

  if (keycloak) {
    if (authenticated && authtorized) {
      component = <WrappedComponent {...{...props, keycloak}}/>;
    } else if (authenticated && !authtorized) {
      component = <Logout />;
    } else {
      component = <CircularProgress/>;
    }
  } else {
    component = <CircularProgress/>;
  }

  return component;
}


const Logout = () => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1} flexDirection={'column'}>
      <Box mb={2}>
        <Typography variant={'h3'}>You are not authorized</Typography>
      </Box>
      <Button variant={'contained'} color={'primary'} onClick={() => keycloak.logout({redirectUri: document.location.origin})}>Logout</Button>
    </Box>
  )
}
