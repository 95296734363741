import {Box, Typography} from "@mui/material";
import React from "react";
import InfoCard from "../../../shared/components/InfoCard";

function ContractorAlreadyApplied() {
  return <InfoCard
    sx={{marginBottom: "16px"}}
    title={'Thank you for applying, your profile and background are being reviewed by the company.'}
    content={<>
      <Typography>
        Next steps:
      </Typography>
      <Box component={'ul'} sx={{paddingInlineStart:'27px', '& *': {fontSize: '16px', lineHeight: 1.5}}}>
        <li>Please make sure <Box component={'a'} sx={{color:'#2C92D5', textDecorationLine:'underline', cursor:'pointer'}} href={'#calendar'}>your calendar availability</Box>  is up to date for the next few weeks showing when you are available for an interview.</li>
        <li>The company will schedule an interview based on the time that you are available on that calendar.</li>
        <li>You will receive a notification that an interview has been scheduled.</li>
        <li>An MDliaison account manager will reach out to you to schedule a pre-call meeting, tell you about the role, and answer any questions you have.</li>
      </Box>
    </>}
  />
}


export {ContractorAlreadyApplied}