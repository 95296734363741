import {PositionMatchOutput, useCandidatesByPositionQuery} from "../../../../api/sdl";
import React from "react";

export default function useCandidatesByPosition(positionId: string, initialCandidateId?: string) {
  const [candidates, setCandidates] = React.useState<PositionMatchOutput[]>([]);
  const {data, refetch, loading, called} = useCandidatesByPositionQuery({fetchPolicy: 'no-cache', variables: {positionId}});

  React.useEffect(() => {
    if (data?.candidatesByPosition) {
      const cand = data?.candidatesByPosition.filter(v => !!v.contractorProfile)
      if (cand.length) {
        if (initialCandidateId) {
          const candidate = data?.candidatesByPosition.find(v => v.contractorId === initialCandidateId)
          if (candidate) {
            setCandidates([candidate, ...cand.filter(c => c.contractorId !== initialCandidateId) as any])
          } else {
            setCandidates(cand as any)
          }
        } else {
          setCandidates(cand as any)
        }
      }
    }
  }, [data, initialCandidateId])

  return {candidates, refetch, loading : loading || !called};
}
