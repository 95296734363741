import {
  Box,
  Card,
  Typography
} from "@mui/material";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import usePageSize from "./hooks/usePageSize";
import usePage from "./hooks/usePage";
import {
  Company, CompanyOutput,
  useCompanyListQuery
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../components/DataGrid";

const columns: DataGrigColumn<Partial<CompanyOutput>>[]  = [{
  title: 'Name',
  value: o => o.companyProfile?.name || ''
}, {
  title: 'Url',
  value: o => o.companyProfile?.url || '',
}, {
  title: 'Owner',
  value: o => <>
    {[o.companyOwner?.firstName, o.companyOwner?.lastName].filter(v => !!v).join(' ')}
    <Typography >{o.companyOwner?.email}</Typography>
  </>,
}, {
  title: 'Manager',
  value: o => <>
    {[o.accountManager?.firstName, o.accountManager?.lastName].filter(v => !!v).join(' ')}
    <Typography >{o.accountManager?.email}</Typography>
  </>,
}, {
  title: 'Open positions',
  value: o => o.openPositions?.toString() || '',
}, {
  title: 'Scheduled meetings',
  value: o => o.scheduledMeetings?.toString() || '',
}]

export default function CompanyList() {
  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [page, setPage] = usePage();
  const history = useHistory();
  const {loading, data, error} = useCompanyListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      page: {
        size: rowsPerPage,
        page
      }
    },
  });

  const total = useMemo(() => {
    return data?.companyList.totalElements || 0;
  }, [data?.companyList.totalElements]);

  const rows: Partial<CompanyOutput>[] = React.useMemo(() => {
    return data?.companyList.data || [];
  }, [data?.companyList.data])

  return <div>
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>Companies</Typography>
    </Box>
    <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={rowsPerPage}
                onRowClick={row => history.push('/company/' + row.companyProfile?.id)}
                page={page}
                total={total}
                error={error?.message}
                onNextClick={() => setPage(p => p + 1)}
                onPrevClick={() => setPage(p => p - 1)}
                loading={loading}
                isLastPage={data?.companyList?.isLast}
      />
    </Card>
  </div>
}
