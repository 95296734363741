import {Box, CircularProgress, Button} from "@mui/material";
import React from "react";
import {useHistory} from "react-router-dom";
import {
  useContractorProfileByIdLazyQuery,
} from "../../../api/sdl";
import {ContractorCard} from "../../../widget";
import CreateContractorForm from "../../../shared/components/CreateContractorForm";
import {useSnackbar} from "notistack";
import ContractorApprovement from "../../components/ContractorApprovement";


export default function CreateContractor() {
  const history = useHistory();
  const [profileId, setProfileId] = React.useState<string>();
  const [getProfile, {data, loading, refetch}] = useContractorProfileByIdLazyQuery({fetchPolicy: 'no-cache'});
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (profileId) {
      getProfile({variables: {id: profileId}});
    }
  }, [profileId])

  const profile = React.useMemo(() => {
    return data?.contractorProfileById
  }, [data?.contractorProfileById])

  return <Box sx={{position: 'relative', minHeight: '50vh'}}>
    <Button variant={'text'} sx={{mb: 3}} color={'primary'}
            onClick={() => history.push('/contractors')}>Back</Button>
    {!profile && <CreateContractorForm onCompleted={data => setProfileId(data.contractorProfileId)}/>}
    {!!profile && <>
      <ContractorCard profile={profile.contractorProfile || undefined} editable userProfile={profile.profile || undefined}
                      extraContent={profile.contractorProfile ? <ContractorApprovement profile={profile.contractorProfile} onChange={refetch}/> : undefined}
                      onEdit={() => {
                        enqueueSnackbar('Profile was saved successfully', {variant: 'success'})
                        refetch()
                      }}
                      showSideStripe={false}
      />
    </>}
  </Box>
}
