import {
  Box,
  Card, IconButton, Input, InputAdornment, ListItem, MenuItem, Select, TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {
  ExternalContractorOutput, SearchTermsInput, useExternalContractorSearchQuery, useStatesQuery
} from "../../../api/sdl";
import DataGrid, {DataGrigColumn} from "../../components/DataGrid";
import useSessionStorageState from "../../../shared/hooks/useSessionStorageState";
import {repsClient} from "../../../ApolloClient";
import ExternalContractorHistoryList from "./ExternalContractorHistoryList";
import {XCircle} from "phosphor-react";
import { useDebouncedCallback } from "../../../shared/hooks/useDebouncedCallback";
import {useDebouncedEffect} from "../../../shared/hooks/useDebouncedEffect";

const columns: DataGrigColumn<ExternalContractorOutput>[]  = [{
  title: 'Name',
  value: o => o.firstName + ' ' + o.lastName
}, {
  title: 'Email',
  value: o =>  <div>
    <Typography>{o.email}</Typography>
    <Typography variant={'body2'} sx={{opacity: .6}}>{o.email2}</Typography>
  </div>,
}, {
  title: 'Industry',
  value: o => (o.industry || '').replaceAll(',', ', ').replaceAll('_', ' '),
}, {
  title: 'JobFunctions',
  value: o => (o.jobFunctions || '').replaceAll(',', ', ').replaceAll('_', ' '),
}, {
  title: 'Medical Sales Experience',
  value: o => o.medicalSalesExperience?.toString() || '',
}, {
  title: 'Address',
  value: o => <div>
    <Typography>{o.municipality}</Typography>
    <Typography variant={'body2'} sx={{opacity: .6}}>{o.region}</Typography>
  </div> || '',
}]

export default function ExternalContractorList() {
  const [rowsPerPage, setRowsPerPage] = usePageSize();
  const [filter, setFilter] = useFilter();
  const [term, setTerm] = React.useState(filter.term || '');
  const [page, setPage] = usePage();
  const [workHistory, setWorkHistory] = useState<{[key: string]: string}[]>()
  const {loading, data, error} = useExternalContractorSearchQuery({
    client: repsClient,
    variables: {
      terms: filter,
      page: {
        size: rowsPerPage,
        page
      }
    },
  });

  const statesData = useStatesQuery({client: repsClient});

  const rows: ExternalContractorOutput[] = React.useMemo(() => {
    return data?.externalContractorSearch.data || [];
  }, [data?.externalContractorSearch.data])

  const total = useMemo(() => {
    return data?.externalContractorSearch.total || 0;
  }, [data?.externalContractorSearch.data]);

  useEffect(() => {
    if (total) {
      const totalPages = Math.floor(total / rowsPerPage)
      if (totalPages < page) {
        setPage(totalPages)
      }
    }
  }, [total, page, rowsPerPage]);

  const onRowClick = (row: ExternalContractorOutput) => {
    if (row.workhistorylist) {
      setWorkHistory(JSON.parse(row.workhistorylist));
    }
  }

  useDebouncedEffect(() => {
    setFilter(prev => ({...prev, term: term ? term : null}))
  }, 500, [term])

  const states = useMemo(() => {
    return statesData.data?.states || [];
  }, [statesData.data]);

  return <div>
    <ExternalContractorHistoryList workHistory={workHistory} onClose={() => setWorkHistory(undefined)} />
    <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: 2}}>
      <Typography variant={'h3'} sx={{ml: 2}}>External Contractors</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end', gap: 2}}>
        <Select size={'small'}
                disabled={statesData.loading}
                value={filter.state || 'all'}
                onChange={(e) => (
                  setFilter({...filter, state: e.target.value === 'all' ? undefined : e.target.value})
                )}
        >
          <MenuItem value={'all'}>All states</MenuItem>
          {states.map(v => <MenuItem key={'st' + v } value={v}>{v}</MenuItem>)}
        </Select>

        <TextField placeholder="Search"
                   size={'small'}
                   sx={{width: 300}}
                   data-test='search'
                   value={term}
                   onChange={e => setTerm(e.currentTarget.value)}
                   InputProps={{
                     endAdornment: term && <InputAdornment position="end">
                       <IconButton
                         size="small"
                         color="secondary"
                         onMouseDown={(event) => {
                           event.stopPropagation();
                         }}
                         onClick={() => {
                           setTerm('');
                         }}
                       >
                         <XCircle size={20} color="#000000" weight="light" />
                       </IconButton>
                     </InputAdornment>,
                   }}
        />
      </Box>
    </Box>
    <Card sx={{mt: 3, p: 0}}>
      <DataGrid columns={columns}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                error={error?.message}
                onRowClick={onRowClick}
                total={total}
                onNextClick={() => setPage(p => p + 1)}
                onPrevClick={() => setPage(p => p - 1)}
                loading={loading}
                isLastPage={total <= rowsPerPage * (page + 1)}
      />
    </Card>
  </div>
}

function usePageSize() {
  return useSessionStorageState<number>(
    'externalContractorListPageSize',
    20
  )
}

function usePage() {
  return useSessionStorageState<number>(
    'externalContractorListPage',
    0
  )
}

function useFilter() {
  return useSessionStorageState<SearchTermsInput>(
    'externalContractorListFilter',
    {}
  )
}
