import {MeetingDetailsItem} from "./types";
import {DateTime} from "luxon";

export function candidateLink(isAdmin: boolean, item: {positionId: string, contractorId: string, companyId?: string}) {
  if(isAdmin) {
    // /company/:id/position/:positionId?/:contractorId?
    return `/company/${item.companyId}/position/${item.positionId}/${item.contractorId}`
  } else {
    // /candidates/:id/:candidateId?
    return `/candidates/${item.positionId}/${item.contractorId}`
  }
}

export function scheduleMeetingLink(item: {positionId: string, positionMatchId: string}) {
  return `/candidates/${item.positionId}/${item.positionMatchId}/schedule`
}

export function meetingSortFunc(a: {meetingScheduledOn: string}, b: {meetingScheduledOn: string}, current?: string) {
  let aDate: number, bDate: number;

  if (current) {
    aDate = DateTime.fromISO(a.meetingScheduledOn).diff(DateTime.fromISO(current)).milliseconds;
    bDate = DateTime.fromISO(b.meetingScheduledOn).diff(DateTime.fromISO(current)).milliseconds;
  } else {
    aDate = DateTime.fromISO(a.meetingScheduledOn).diffNow().milliseconds;
    bDate = DateTime.fromISO(b.meetingScheduledOn).diffNow().milliseconds;
  }
  if (aDate === bDate) {
    return 0
  }

  if (aDate > 0 && bDate > 0) {
    // asc for future meetings
    return aDate - bDate > 0 ? 1 : -1;
  } else if (aDate < 0 && bDate < 0) {
    // desc for past meetings
    return aDate - bDate > 0 ? -1 : 1;
  } else {
    return aDate - bDate > 0 ? -1 : 1;
  }
}

export const isDateInFuture = (date: string) => (
  DateTime.fromISO(date).diffNow().milliseconds > 0
)