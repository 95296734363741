import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import {Play} from "phosphor-react";
import {FileStorage, useUploadAnswerUrlMutation} from "../../api/sdl";
import {getFilesUrl} from "../utils";
import config from "../../config";
import { enqueueSnackbar } from "notistack";

interface VideoRecordModalProps {
  open: boolean,
  loading: boolean,
  onClose: () => void,
  title: string,
  onRecorded: (data: Blob) => void,
  videoQuestionId?: number,
  url?: string
}

function VideoRecordModal(props: VideoRecordModalProps) {
  const [cameraStream, setCameraStream] = React.useState<any>();
  const [recording, setRecording] = React.useState(false);
  const [blob, setBlob] = React.useState<Blob>();
  const [mediaRecorder, setMediaRecorder] = React.useState<any>();
  const [showCamera, setShowCamera] = useState(true)
  const [url, setUrl] = useState<string>()
  const [countdown, setCountdown] = useState(0);
  const [countdownInterval, setCountdownInterval] = useState<any>()

  useEffect(() => {
    setUrl(props.url)
  }, [props.url]);

  const startRecording = () => {
    let options: any;
    if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      options = { mimeType: 'video/webm;codecs=h264' }; //Chromium based browsers will use this mimeType.
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"', videoBitsPerSecond: 2500000 }; //Safari uses this mimeType.
    }

    const media_recorder = new MediaRecorder(cameraStream, options);
    const blobs_recorded: any[] = [];
    setBlob(undefined);

    media_recorder.addEventListener('dataavailable', function(e) {
      blobs_recorded.push(e.data);
    });

    media_recorder.addEventListener('stop', function() {
      // setBlob(new Blob(blobs_recorded, { type: 'video/mp4' }));
      setBlob(new Blob(blobs_recorded, { type: options.mimeType }));
    });


    setMediaRecorder(media_recorder)

    setCountdown(3)
    setCountdownInterval(setInterval(() => {
      setCountdown(prev => {
        const val = prev - 1;

        if (val <= 0) {
          if (media_recorder.state !== 'recording') {
            media_recorder.start(1000);
          }
          if (countdownInterval) {
            clearInterval(countdownInterval);
          }
        }

        return val;
      })
    }, 1000))

  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setCountdown(0)
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    }
  };

  const onRecordClick = () => {
    setRecording(prev => {
      const newVal = !prev;
      if (newVal) {
        if (!showCamera) {
          setCameraStreamToVideo()
        }
        startRecording();
      } else {
        stopRecording()
        setUrl(undefined);
      }
      return newVal;
    });
  };

  const setCameraStreamToVideo = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true})
    let video = document.querySelector("#video");
    (video as any).srcObject = stream;
    setCameraStream(stream)
    setShowCamera(true)
  }, []);

  const setUrlToVideo = useCallback(async () => {
    if (url) {
      setShowCamera(false)
      let video = document.querySelector("#video-play");
      if (video) {
        (video as any).srcObject = null;
        (video as any).src = url;
        (video as any).load();
      }
    }
  }, [url]);

  useEffect(() => {
    if (blob) {
      props.onRecorded(blob)
    }
  }, [blob]);

  React.useEffect(() => {
    if (props.open) {
      setBlob(undefined);
      setTimeout(() => {
        setCameraStreamToVideo()
      }, 300)
    } else if(recording) {
      stopRecording();
    }
    if (!props.open) {
      setBlob(undefined);
      setRecording(false)
    }
  }, [props.open]);

  const clearVideo = () => {
    cameraStream.getTracks().forEach((t: MediaStreamTrack) => t.stop());
    let video = document.querySelector("#video");
    if (video) {
      (video as any).srcObject = null;
    }
  }

  return <Dialog open={props.open} onClose={() => {
    clearVideo();
    props.onClose();
  }} sx={{'& .MuiDialog-paper': {maxWidth: 'min-content'}}}>
    <DialogContent
      sx={{
        p: 0,
        position: 'relative',
        maxWidth: {xs: '90vw', md: 'inherit'},
        width: {xs: '90vw', md: '50vw'},
        maxHeight: {xs: '90vh', md: 'inherit'},
        height: {xs: '90vh', md: 'inherit'},
      }}
    >
      {props.loading && <Box sx={{
        zIndex: 3,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.8)'
      }}>
        <CircularProgress size={25}/>
      </Box>}
      {countdown > 0 && <Box sx={{
        zIndex: 2,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
          <Typography variant={'h1'} sx={{fontSize: 70, color: '#fff'}}>{countdown}</Typography>
      </Box>}
      <Box sx={{display: 'flex', gap: 2, alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <Typography  sx={{textAlign: 'center', p: '10px'}}><b>{props.title}</b></Typography>
        <IconButton
          sx={{mr: 1, mt: 1}}
          onClick={() => {props.onClose()}}
        >
          <img src={'/img/teenyicons/x.svg'} width={25} height={25} />
        </IconButton>
      </Box>
      <Box component={'video'} sx={{display: showCamera ? 'block' : 'none', width: {xs: 1, md: '50vw'}, height: {xs: 1, md: 'inherit'}, borderRadius: '0 0 4px 4px'}} id="video" muted={!!showCamera} autoPlay={true}></Box>
      <Box component={'video'} sx={{display: showCamera ? 'none' : 'block', width: {xs: 1, md: '50vw'}, height: {xs: 1, md: 'inherit'}, borderRadius: '0 0 4px 4px'}} id="video-play" muted={!!showCamera} autoPlay={true}></Box>

      <Box sx={{position: 'absolute', width: 1, px: 2, zIndex: 2, gap: 2, bottom: 16, display: 'flex', justifyContent: 'space-between'}}>
        <Button size={'large'} fullWidth variant={'contained'} onClick={onRecordClick} disabled={props.loading} color={recording ? 'primary' : 'error'}>
          {!recording ? 'Record' : 'Stop'}
        </Button>
        {(!props.loading && url && !recording) && (
          <Button size={'large'} fullWidth variant={'contained'} startIcon={<Play size={24} color="#fff" weight="fill" />}
                  onClick={() => {
                    setUrlToVideo();
                  }}>
            Play
          </Button>
        )}
      </Box>


      {/*<IconButton size={'small'} onClick={() => {
        clearVideo();
        props.onClose();
      }} variant={'text'} sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 2,
        background: '#000',
        borderRadius: '50%',
        '&:hover': {background: '#000'},
      }}>
        <X size={20} color="#fff" weight="bold" />
      </IconButton>*/}
    </DialogContent>
  </Dialog>
}

type VideoRecordModalDataProvider = Omit<VideoRecordModalProps & {contractorId?: string, onRecorded: () => void}, "loading" | "url">

const VideoRecordModalDataProvider = (props: VideoRecordModalDataProvider) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>()
  const [saveVideo, saveVideoData] = useUploadAnswerUrlMutation({
    onError: err => () => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar('Video was uploaded successfully', {variant: 'success'});
    },
  });

  const onRecorded = React.useCallback( (blob: Blob) => {
    if (props.videoQuestionId !== undefined) {
      setUrl(undefined);
      setLoading(true)
      const formData = new FormData();

      formData.append('file', blob, props.contractorId + '_' + props.videoQuestionId + '.mp4');
      formData.append('contractorProfileId', props.contractorId || '');
      formData.append('prefix', 'video');

      fetch(
        config.uploadUrl + '/api/upload',
        {
          method: 'POST',
          body: formData,
          headers: {
          }
        }
      ).then(res => res.text()).then(res => {
        setUrl(getFilesUrl(FileStorage.S3) + '/' + res)
        return saveVideo({variables: {
            payload: {
              videoQuestionId: props.videoQuestionId,
              url: res,
            }
          }})
      }).then(props.onRecorded)
        .catch(() => {
          throw new Error('Record video failed')
        })
        .finally(() => setLoading(false))
      ;
    }
  }, [props.videoQuestionId])

  const onClose = () => {
    setUrl(undefined);
    setLoading(false);
    props.onClose();
  }

  return <VideoRecordModal {...props} loading={loading} onClose={onClose} onRecorded={onRecorded} url={url} />
}

export default VideoRecordModalDataProvider;
