import config from "../../../../config";
import React from "react";
import {Box, Button, Link} from "@mui/material";

export function DownloadGeneratedResume({url}: { url?: string | null }) {
  if (!url) return null;

  const absoluteLink = `${config.s3FilesUrl}/${url}`;
  const last = url.split("/").at(-1)
  return <Box>
    <Button
      sx={{width: 1}}
      onClick={() => {
        window.open(absoluteLink);
      }}
      variant="outlined"
      color="primary"
    >
      Download resume
    </Button>
  </Box>;
}