import {GuestPerson, useScheduleMeetingMutation} from "../../../api/sdl";
import {useSnackbar} from "notistack";

export function useScheduleMeeting(positionMatchId: string, callback: () => void, meetingId?: string) {
  const {enqueueSnackbar} = useSnackbar();
  const [request, {loading}] = useScheduleMeetingMutation({
    onError: err  => enqueueSnackbar(err.graphQLErrors[0].message, {variant: 'error'}),
    onCompleted: data => {
      enqueueSnackbar(`Meeting was ${meetingId ? 're' : ''}scheduled successfully`, {variant: 'success'});
      callback();
    },
  })

  const makeRequest = (guests: GuestPerson[], meetingScheduledOn: string) => request({
    variables: {
      meetingId,
      payload: {
        positionMatchId,
        meetingScheduledOn,
        guests
      }
    }
  })

   const schedule = async (guests: GuestPerson[], meetingScheduledOn: string) => {
     makeRequest(guests, meetingScheduledOn);
  }

  return {schedule, loading}
}