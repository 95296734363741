import {
  Box,
  Button, CircularProgress, Dialog, IconButton, Modal,
  Typography
} from "@mui/material";
import React from "react";
import {Record, Play, X } from 'phosphor-react';
import {
  FileStorage,
  useMyAnswersQuery,
  useVideoQuestionsQuery,
} from "../../api/sdl";
import {useSnackbar} from "notistack";
import {ProfileContext} from "../ProfileContext";
import VideoRecordModal from "./VideoRecordModal";
import {getFilesUrl} from "../utils";
import {LoadingButton} from "@mui/lab";

export default function IntroductionVideoForm({onSubmit}: {onSubmit: () => void}) {
  const videoQuestions = useVideoQuestionsQuery();
  const [profile] = React.useContext(ProfileContext);
  const myAnswers = useMyAnswersQuery({fetchPolicy: 'no-cache'});
  const [index, setIndex] = React.useState<number>();
  const [recordOpen, setRecordOpen] = React.useState(false);
  const [recordOpening, setRecordOpening] = React.useState<number>();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const savedAnswers = React.useMemo(() => {
    return myAnswers?.data?.myAnswers || [];
  }, [myAnswers.data])

  const onRecordClick = async (index: number) => {
    try {
      setIndex(index);
      setRecordOpening(index);
      setRecordOpen(true);
    } catch (error: any) {
      enqueueSnackbar(error.message)
      setRecordOpening(undefined);
      return
    }
    setRecordOpening(undefined);
  }

  const questions = React.useMemo(() => {
    return videoQuestions.data?.videoQuestions || [];
  }, [videoQuestions.data?.videoQuestions])

  const onRecorded = () => {
    myAnswers.refetch()
  }

  React.useEffect(() => {
    setLoading(myAnswers.loading || videoQuestions.loading)
  }, [myAnswers.loading, videoQuestions.loading])

  return <Box sx={{position: 'relative', mt: 5}}>
    <VideoRecordModal open={recordOpen}
                 title={questions && index !== undefined ? questions[index!]?.title : ''}
                 onRecorded={onRecorded}
                 contractorId={profile?.contractorProfile?.contractorId}
                 videoQuestionId={questions[index || 0]?.id}
                 onClose={() => {
                   setIndex(undefined);
                   setRecordOpen(false);
                 }} />
    {loading && <Box sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffffa3', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size="15" />
    </Box>}
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
      {questions.map((q, i) => <Question key={i}
                                         url={savedAnswers.find(a => a.videoQuestion.id === q.id)?.url}
                                         loading={recordOpening === i}
                                         fileStorage={savedAnswers.find(a => a.videoQuestion.id === q.id)?.fileStorage}
                                         disabled={typeof recordOpening !== 'undefined'}
                                         onRecordClick={() => {
                                           onRecordClick(i)
                                         }}
                                         title={q.title}  />)}
      <Box sx={{mt: 3, display: 'flex', gap: 3}}>
        <Button variant={'outlined'} color={'secondary'} onClick={onSubmit}>Back to profile</Button>
      </Box>
    </Box>
  </Box>;
}

const Question = (props: {title: string, onRecordClick: () => void, url?: string, fileStorage?: FileStorage | null, disabled: boolean, loading: boolean}) => {
  const [open, setOpen] = React.useState(false);

  return <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between', gap: 3}}>
    {props.url && <PlayModal url={getFilesUrl(props.fileStorage) + '/' + props.url} open={open} onClose={() => {
      setOpen(false);
    }}/>}
    <Typography>{props.title}</Typography>
    <Box sx={{display: 'flex', gap: 3}}>
      <div>
        <LoadingButton variant={'outlined'} color={'error'} loading={props.loading} disabled={props.disabled}
                       startIcon={<Record size={16} color="#D3232F" weight="fill" />}
                        onClick={props.onRecordClick}>Record</LoadingButton>
      </div>
      <Box sx={{minWidth: 87}}>
        {!!props.url && <Button variant={'outlined'} color={'success'} disabled={props.disabled}
                               startIcon={<Play size={16} color="#1A7D36" weight="fill" />}
                               onClick={() => {
                                 setOpen(true);
                               }}>Play</Button>}
      </Box>
    </Box>
  </Box>
}


const PlayModal = (props: {url: string, open: boolean, onClose: () => void}) => {
  React.useEffect(() => {
    if (props.open && props.url) {
      setTimeout(() => {
        let video = document.querySelector("#video-play");
        // const objectUrl = URL.createObjectURL( props.url );
        (video as any).src = props.url;
        (video as any).load();
      }, 300)
    } else if(props.url) {
      setTimeout(() => {
        /*URL.revokeObjectURL( url );
        setUrl(undefined)*/
      }, 300)
    }
  }, [props.open, props.url]);

  const clearVideo = () => {
    let video = document.querySelector("#video-play");
    if (video) {
      (video as any).src = '';
    }
  }

  return <Dialog open={props.open} sx={{p: 0}} onClose={() => {
    clearVideo();
    props.onClose()
  }}>
    <Box component={'video'} controls sx={{width: {md: '50vw'}, borderRadius: '8px'}} id="video-play" autoPlay={true} style={{display: 'block'}}></Box>
    <IconButton size={'small'} onClick={() => {
      clearVideo();
      props.onClose()
    }} sx={{
      position: 'absolute',
      top: 16,
      right: 16,
      zIndex: 2,
      '&:hover': {background: 'transparent'},
    }}>
      <X size={30} color="#fff" weight="fill" />
    </IconButton>
  </Dialog>
}
